import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart'
import Table from 'libs/Table'
import Button from 'libs/Button'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function ComponentSearcher(params, p2) {
    let {props = {}} = params || {}
    const [open, setOpen] = useState(false);
    const [obj, setObj] = useState({});
    const [value, setValue] = useState('aaa');
    const [components, setComponents] = useState([]);
    const [count, setCount] = useState(0);

    const fetchUser = async () => {
        try {
            let response = await global.http.get('/all-components');
            // let json = await response.json();
            // return { success: true, data: json };
            return {items: response}
        } catch (error) {
            console.log(error);
            return {success: false};
        }
    }
    useEffect(() => {
        (async () => {
            let {items} = await fetchUser();
            setComponents(items);
        })();
    }, []);

    let table;
    let item = props.item || params.item;
    item.childs = item.childs || [];

    let inputRef;
    return <div>
        {/*Component Seracher {value}*/}
        {/*<input type="text" value={value} onChange={(e) => {*/}
        {/*    setValue(e.target.value)*/}
        {/*}*/}
        {/*}/>*/}
        {/*<Smart*/}
        {/*    defSize={12}*/}
        {/*    items={[*/}
        {/*        {*/}
        {/*            name: 'Search ...', key: 'name'*/}
        {/*        },*/}
        {/*    ]}*/}
        {/*    onChange={(v) => {*/}
        {/*        setValue(v.name)*/}
        {/*        table.onSearch && table.onSearch(v.name)*/}
        {/*    }*/}
        {/*    }></Smart>*/}

        <div className="ib">
            {/*<small>Desc / Mobile / Feature</small>*/}
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                disablePortal
                blurOnSelect={true}
                value={value}
                // inputValue={value}
                // onInputChange={(e, newValue) => {
                //     console.log("qqqqqnew value", newValue);
                //     setValue(newValue)
                // }}
                id="combo-box-demo"
                options={components}
                sx={{width: 300}}
                onChange={(e, point) => {
                    setValue({})

                    if (!point) {
                        return;
                    }
                    if (params.onClickItem) {
                        params.onClickItem(point, props, params)
                    } else {
                        item.childs = item.childs || []
                        item.childs.push(point)
                        item.childs = _.uniq(item.childs, v => {
                            return v ? v._id || v : null
                        })
                        props.onChange && props.onChange(item.childs, 'childs')
                    }


                }}
                getOptionLabel={(option) => (option.name || '')}
                renderInput={(params) => {
                    params.value = params.value || '';
                    return (<TextField
                        {...params}
                        inputRef={input => {
                            inputRef = input;
                        }}
                        label="Select component"
                        disableAnimation={true}
                        // InputLabelProps={{
                        //     shrink: false,
                        // }}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />)
                }}
            /></div>
        {/*<div className="ib searcher-button">*/}
        {/*    <Button onClick={(scb) => {*/}
        {/*        scb && scb();*/}
        {/*        inputRef.click();*/}
        {/*        inputRef.focus();*/}
        {/*        setOpen(true)*/}

        {/*    }}>Добавить Компоненту</Button>*/}
        {/*</div>*/}

    </div>
}

export default ComponentSearcher
