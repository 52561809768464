import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import MyAutoComplete from "../MyAutocomplete";
import Input from "../../../libs/Input";
import {PremiaEdit} from "../UserPeriodPreview";
import Button from "../../../libs/Button";
import {ToRub} from "../TopStats";
import Stringify from "../../Stringify";


function OkladEdit(props) {

    let {item, onChange} = props;
    item.special ??= {}
    item.special.dbItems ??= {}
    let special = item.special;
    let {dbItems} = item.special;
    dbItems.oklad_nalog ??= {}

    return <>
        <div className="col-xs-12">
            Траты
        </div>

        <div className="col-xs-12">
            <Smart
                obj={item}
                items={[
                    {
                        name: 'Название', key: 'name', type: 'input'
                    },
                    {
                        path: 'Main/MyAutocomplete',
                        key: 'teamFrom',
                        itemsKey: 'allTeams',
                        label: 'Команда',
                    }, {
                        path: 'Main/MyAutocomplete',
                        key: 'accountFrom',
                        itemsKey: 'accounts',
                        label: 'Аккаунт',
                    }, {
                        name: 'Сумма',
                        key: 'amountFrom',
                        type: 'amount',
                        accountId: item?.accountFrom,
                        periodId: item.period
                    }
                ]}
                onChange={v => {
                    onChange({special: item.special})
                }}
            >
            </Smart>
        </div>
        <div className="col-xs-12">
            <Smart
                obj={item}
                items={[
                   {
                        name: 'Тип траты',
                        key: 'subType',
                        type: 'select',
                       items: window?.info?.subTypes
                    }
                ]}
                onChange={v => {
                    onChange({special: item.special})
                }}
            >
            </Smart>
        </div>
    </>
}


export default OkladEdit
