import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../../Stringify";
import {safeNum} from "../../methods/GenerateHistory";
import {ToRub} from "../TopStats";
import Graphic from "./Graph";
import Select from "../../../libs/Select";
import GroupButton from "../../../libs/GroupButton/GroupButton";

export const calcObj = (periods, obj, keysFn) => {
    let res = {}
    let keys = {};
    _.each(periods, (it, periodInd) => {
        let period = it.name;
        let arr = obj[period]

        res[period] ??= {};

        _.each(arr, (item, ind) => {
            _.each(item.items, (it, itemInd) => {
                let keys = keysFn(it, item)

                let {amount} = it;


                _.each(keys, (key, ind) => {
                    // if (Array.isArray(key)) {
                    //     let keyItem = key[0];
                    //     let key = key[1];
                    //
                    // } else {
                    //     let keyItem = key

                    res[period][key] ??= {total: 0, positive: 0, negative: 0, _ids: {}, fullIds: {}}
                    res[period][key].total += amount;
                    res[period][key].positive += amount > 0 ? amount : 0;
                    res[period][key].negative += amount < 0 ? amount : 0;
                    res[period][key]._ids[item._id] = true;

                    // }


                    // keys[key] = true;
                    // res[period][key].items.push({amount, key, _id: item._id, itemInd})
                })

            })
        })

        let prevPeriod = (periods[periodInd - 1] || {}).name;

        let prev = res[prevPeriod] || {}
        let cur = res[period] || {}


        let ext = (i, key) => {
            let gv = (st, subKey) => {
                return safeNum(((st || {})[key] || {})[subKey], 0)
            }
            let prevFinal = gv(prev, 'final')//safeNum((prev[key] || {}).final, 0);
            let total = gv(cur, 'total')
            let final = prevFinal + total;
            res[period][key] ??= {}
            res[period][key].final = gv(prev, 'final') + gv(cur, 'total')
            res[period][key].finalPositive = gv(prev, 'finalPositive') + gv(cur, 'positive')
            res[period][key].finalNegative = gv(prev, 'finalNegative') + gv(cur, 'negative')
            res[period][key].perc = !prevFinal ? '-' : Math.round(((final - prevFinal) / (final)) * 100)
            res[period][key].fullIds = {...prev?.fullIds || {}, ...cur._ids || {}}
        }

        _.each(prev, ext)
        _.each(cur, ext)

    })

    return {groupped: res}
}

function ObototStats(props) {
    let {obj, periods, groupFn} = props;
    let {groupped} = calcObj(periods, obj, groupFn)
    return <div>
        <RenderPeriods
            {...props}
            groupped={groupped}
        ></RenderPeriods>

    </div>
}

export function RenderPeriods(props) {
    let defSubKey = 'final'
    let [renderType, setRenderType] = useState(props.renderType || defSubKey);
    let {periods, isVertical, onOpen,  pubName, title, graphics, tableFields, groupped} = props;
    console.log("qqqqq prospssssssssssssssssssssssssss", props);
    let graphPeriods = [...periods].reverse()
    let [sorting, setSorting] = useState('');


    useEffect(() => {

    }, [])
    isVertical = isVertical || pubName;
    console.log("qqqqq gggggggggggggggggggggggggggggg", groupped);
    const beautifulColors = [
        '#1f77b4', // Blue
        '#ff7f0e', // Orange
        '#2ca02c', // Green
        '#d62728', // Red
        '#9467bd', // Purple
        '#8c564b', // Brown
        '#e377c2', // Pink
        '#7f7f7f', // Gray
        '#bcbd22', // Olive
        '#17becf', // Cyan
        '#1a9850', // Dark Green
        '#66c2a5', // Light Green
        '#fdae61', // Light Orange
        '#b2182b', // Dark Red
        '#a6cee3', // Light Blue
        '#6a3d9a', // Dark Purple
        '#b15928', // Dark Brown
        '#fb9a99', // Light Pink
        '#e41a1c', // Bright Red
        '#fdbf6f', // Bright Yellow
    ];

    let fieldsPeriods;
    if (pubName) {
        let obj = {}
        _.each(groupped, (item, period) => {
            _.each(item, (item, field) => {
                obj[field] = obj[field] || {name: pubName(field), field, key: field}
            })
        })
        fieldsPeriods = Object.keys(obj).map(key => obj[key])
        console.log("qqqqq obj444444444444", obj, fieldsPeriods, sorting);

        fieldsPeriods = sorting ? _.sortBy(fieldsPeriods, it => {
            let value = ((groupped[sorting.key] || {})[it.key] || {})[renderType]
            console.log("qqqqq tttttttttttttttttt", it, value, sorting.direction);
            return (sorting.direction * safeNum(value))
        }) : fieldsPeriods;
    }
    fieldsPeriods = fieldsPeriods || graphPeriods

    return <>
        <h2 style={{fontSize: '16px', padding: '10px'}}>{title || '--'}</h2>
        <GroupButton items={['total', 'full', 'final', 'fullPN', 'monthlyPN', 'finalPositive', 'finalNegative', 'positive', 'negative' ].map(it => {
            return {name: it, value: it}
        })}
                     value={renderType}
                onChange={v => {
                    setRenderType(v)
                }}
        ></GroupButton>
        {!pubName && <div>
            {(graphics || []).map((graphFields, ind) => {
                return (<div key={ind} style={{width: `${100 / graphics.length}%`, display: 'inline-block'}}>
                    <Graphic
                        labels={graphPeriods.map(it => it.name)}
                        datasets={
                            graphFields.map((field, ind) => {
                                let {key, name, subKey = renderType} = field;
                                return {
                                    label: name,
                                    data: graphPeriods.map(it => {
                                        let period = it.name;
                                        let value = ((groupped[period] || {})[key] || {})[subKey] || 0;
                                        return value
                                    }),
                                    borderColor: beautifulColors[ind],
                                }
                            })
                        }
                    ></Graphic>
                </div>)
            })}</div>}
        {pubName && <div>
            {(graphics || []).map((graphFields, ind) => {
                return (<div key={ind} style={{width: `${100 / graphics.length}%`, display: 'inline-block'}}>
                    <Graphic
                        labels={graphPeriods.map(it => it.name)}
                        datasets={
                            fieldsPeriods.map((field, ind) => {
                                let {key, name, subKey = renderType} = field;
                                return {
                                    label: name,
                                    data: graphPeriods.map(it => {
                                        let period = it.name;
                                        let value = ((groupped[period] || {})[key] || {})[subKey] || 0;
                                        return value
                                    }),
                                    borderColor: beautifulColors[ind],
                                }
                            })
                        }
                    ></Graphic>
                </div>)
            })}</div>}


        {isVertical && <div className={'custom-scroll'}>
            <table
                className="table table-striped table-hover table-bordered tbodyStats table-reserved table-responsive ">
                <thead>
                <tr>
                    <th>Период</th>
                    {(periods || []).map((it, ind) => {
                        let field = it.field || it.key || it.name || '';
                        let name = it.name || it;
                        return (<th key={ind} onClick={() => {
                            setSorting({
                                key: field,
                                direction: sorting.key == field ? sorting.direction * (-1) : sorting.direction || 1
                            })
                        }}>
                            {name || field}
                        </th>)
                    })}
                </tr>
                </thead>
                {(fieldsPeriods || []).map((it, ind) => {

                    let field = it.key || field;

                    return (<tbody key={ind}>
                    <tr>
                        <td>
                            <strong>
                                {it.name}
                            </strong>
                        </td>
                        {(periods || []).map((it, ind) => {
                            let period = it.name || ''
                            let st = (groupped[period] || {})[field] || {}

                            return (<td key={ind} onClick={() => {
                                console.log("qqqqq click item", st);
                                onOpen && onOpen(st._ids)
                            }}>
                                {renderType == 'full' && <><ToRub>{st.final}</ToRub> <ToRub>{st.total}</ToRub>
                                    <div className="pull-right">{st.perc}%</div>
                                </>}
                                {renderType == 'fullPN' && <><ToRub>{st.finalPositive}</ToRub> <ToRub>{st.finalNegative}</ToRub>
                                </>}{renderType == 'monthlyPN' && <><ToRub>{st.positive}</ToRub> <ToRub>{st.negative}</ToRub>
                                </>}
                                {renderType == 'perc' && <div className="pull-right">{st.perc}%</div>}
                                {/total|final|positive|negative/gi.test(renderType) && <ToRub>{st[renderType]}</ToRub>}
                            </td>)
                        })
                        }
                    </tr>
                    </tbody>)
                })}
            </table>
        </div>}
        {!isVertical && <table className="table table-striped table-hover table-bordered tbodyStats">
            <thead>
            <tr>
                <th>Период</th>
                {(tableFields || []).map((it, ind) => {
                    let field = it.field || it;
                    let name = it.name || it;
                    return (<th key={ind}>
                        {name || field}
                    </th>)
                })}
            </tr>
            </thead>
            {(periods || []).map((it, ind) => {
                let period = it.name || ''
                return (<tbody key={ind}>
                <tr>
                    <td>
                        <strong>
                            {period}
                        </strong>
                    </td>
                    {(tableFields || []).map((it, ind) => {
                        let field = it.key || field;
                        renderType = it.renderType || renderType;

                        let st = (groupped[period] || {})[field] || {}

                        return (<td key={ind} onClick={() => {
                            console.log("qqqqq click item", st);
                            onOpen && onOpen(st._ids)
                        }}>
                            {renderType == 'full' && <><ToRub>{st.final}</ToRub> <ToRub>{st.total}</ToRub>
                                <div className="pull-right">{st.perc}%</div>
                            </>}
                            {renderType == 'perc' && <div className="pull-right">{st.perc}%</div>}
                            {renderType == 'final' && <ToRub>{st.final}</ToRub>}
                            {renderType == 'total' && <ToRub>{st.total}</ToRub>}
                        </td>)
                    })
                    }
                </tr>
                </tbody>)
            })}
        </table>}
    </>
}

export default ObototStats
