import React, {useRef, useState, useEffect} from 'react';
import MyModal from "../../libs/MyModal";
import Stringify from "../Stringify";


function Layout2(props) {
    let [isOpen, setIsOpen] = useState(true)
    let [item, setItems] = useState(props.item)
    let [originalItem, setOriginalItems] = useState(props.item)

    useEffect(() => {
        console.log("qqqqq isOpen", isOpen);
        if (!isOpen) {
            window.hideModal(props)
        }
    }, [isOpen])

    return <div>
        <MyModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            Account Modal
            <Stringify item={item}></Stringify>
        </MyModal>
    </div>
}

export default Layout2
