import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import Button from "../../libs/Button";
import {pubAccount, pubContract, pubEmployee, pubTeam, pubType, pubUser} from "./methods";
import {ToRub} from "./TopStats";
import MyModal from "../../libs/MyModal";
import {isGoodRole} from "../../App";


function Layout2(props) {
    let {statPeriod, transactions, onChangeStats} = props;
    let [isOpen, setIsOpen] = useState(false)
    console.log('*........ ## ROOT RENDER', props);

    return <div className={'w100'} title={'#' + statPeriod?._id}>

        {/*{statPeriod?.period}*/}
        <div></div>
        USD: {statPeriod?.currency?.USD}
        <div></div>
        EUR: {statPeriod?.currency?.EUR}
        {isGoodRole('sign') && <div>
            <a
                onClick={() => {
                    setIsOpen(true)
                }}>Подписать</a>
        </div>}
        <MyModal
            size={'full'}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <div><CompareStats
                onChangeStats={onChangeStats}
                statPeriod={statPeriod}
                transactions={transactions}></CompareStats></div>
        </MyModal>

    </div>
}

function CompareStats(props) {
    let {transactions, statPeriod, onChangeStats} = props;
    let groups = {
        byAccountsZP: {},
        byAccountsContract: {},
        byTeams: {},
        byEmployeeZP: {},
        byContracts: {},
        byTransactions: {}
    };
    let transactionsObj = {}
    _.each(transactions, (transaction, ind) => {
        transactionsObj[transaction._id] = transaction
        let {type} = transaction
        _.each(transaction.items, (item, ind) => {
            let {amount, team, account, employee, contract} = item;
            employee = employee || 0
            contract = contract || 0
            team = team || 0
            account = account || 0
            if (type === 'prihod') {
                groups.byContracts[contract] = (groups.byContracts[contract] || 0) + (amount > 0 ? amount : 0);
                groups.byAccountsContract[account] = (groups.byAccountsContract[account] || 0) + amount;
            }

            if (type === 'oklad') {
                groups.byAccountsZP[account] = (groups.byAccountsZP[account] || 0) + amount;
                groups.byEmployeeZP[employee] = (groups.byEmployeeZP[employee] || 0) + (amount > 0 ? 0 : amount);
            }
            groups.byTeams[team] = (groups.byTeams[team] || 0) + amount;
            groups.byTransactions[transaction._id] = (groups.byTransactions[transaction._id] || 0) + amount;
        })
    })

    let completeMonth = (scb, field) => {
        scb && scb()
        let details = field ? {[field]: groups[field]} : groups

        // details = groups;

        global.http.post('/sign-period', {_id: statPeriod._id, details: details}).then(r => {
            scb && scb()
            onChangeStats && onChangeStats(r)
        })
    }
    let keys = [{
        key: 'byAccountsZP',
        name: 'Аккаунты ЗП',
        pub: pubAccount,
    }, {
        key: 'byAccountsContract',
        name: 'Аккаунты Приход',
        pub: pubAccount,
    }, {
        key: 'byContracts',
        name: 'Контракты',
        pub: pubContract,
    }, {
        key: 'byTeams',
        name: 'Команды',
        pub: pubTeam,
    }, {
        key: 'byEmployeeZP',
        name: 'ЗП',
        pub: pubEmployee,
    }, {
        key: 'byTransactions',
        name: 'Транзакции',
        pub: (_id) => {
            console.log("qqqqq vvvvvvvvvvvvvvvv", _id);
            let tr = transactionsObj[_id];
            return `#${_id} ${pubType(tr?.type)}`
        },
        onClick: (_id) => {
            console.log("qqqqq open      dddddddddddddddddddd", _id);
            window.openMainModal({
                type: 'transaction', item: transactionsObj[_id]
            })
        }
    }]
    //, {name: 'byContracts'} , 'byTeams', 'byEmployeeZP', 'byTranscations']


    let prevDetails = statPeriod?.signDetails || {}
    let signDates = statPeriod?.signDates || {}
    return <>

        <Button onClick={(scb) => {
            completeMonth(scb)
        }}>Подписать все</Button>

        <div className="row">
            <hr/>
        </div>
        <div className="row">
            <div className="col-xs-2"></div>
        </div>
        {(keys || []).map((it, ind) => {
            let key = it.key;
            let g1 = groups[key] || {}
            let g2 = prevDetails[key] || {}
            let fieldkeys = Object.keys({...g1, ...g2})
            console.log("qqqqq fieldkeysfieldkeys", prevDetails, statPeriod);
            let signInfo = (signDates || {})[key] || {}
            let m = window.m;
            return (<div key={ind} className={'row'}>
                <div className="col-xs-2">
                    {it.name}
                    <div>
                        <Button color={4} size={'xs'} onClick={(scb) => {
                            completeMonth(scb, key)
                        }}>Подписать</Button>
                        <div></div>
                        {m.date_time(signInfo.cd)}
                        <div></div>
                        {pubUser(signInfo.user)}
                    </div>

                </div>
                <div className="col-xs-10">
                    {(fieldkeys || []).map((field, ind) => {
                        let isEq = g1[field] == g2[field]
                        return (<div key={ind} className={'row ' + (!isEq ? 'fieldErr' : '')} onClick={() => {
                            it.onClick && it.onClick(field)
                        }}>
                            <div className="col-xs-2">
                                {!isEq && <>
                                    <div className="fa fa-warning"></div>
                                </>}
                                {isEq && <>
                                    <div className="fa fa-check"></div>
                                </>}
                                {it.pub(field)}
                            </div>
                            <div className="col-xs-5">
                                <span style={{marginRight: '10px'}}>Стало: <ToRub
                                    woPull={true}>{g1[field]}</ToRub></span>
                                <span>Было: <ToRub woPull={true}>{g2[field]}</ToRub></span>
                            </div>
                        </div>)
                    })}
                </div>
                <div className="col-xs-12">
                    <hr/>
                </div>
            </div>)
        })}
        {/*{(transactions || []).map((it, ind) => {*/}
        {/*    return (<div key={ind}>*/}
        {/*        {it._id}*/}
        {/*    </div>)*/}
        {/*})}*/}
        {/*<Stringify item={groups}></Stringify>*/}
        {/*<Stringify item={statPeriod}></Stringify>*/}
    </>
}

export default Layout2
