import React, {useState, useEffect} from 'react';
import Stringify from "../Stringify";
import Smart from "../../libs/Smart";
import Button from "../../libs/Button";
import {createTransaction, generateHistory, pubType, saveTransaction, statusObj} from './methods';
import MyAutoComplete from "./MyAutocomplete";
import Input from "../../libs/Input";
import {PremiaEdit} from "./UserPeriodPreview";
import GeneralTr from "./Tr/GeneralTr";
import OkladEdit from "./Tr/OkladEdit";
import PrihodEdit from "./Tr/PrihodEdit";
import RasprediEdit from "./Tr/RasprediEdit";
import PerevodEdit from "./Tr/PerevodEdit";
import TratiEdit from "./Tr/TratiEdit";
import NalogOfficeEdit from "./Tr/NalogOfficeEdit";

export const paidStatuses = [{name: '-', key: ''}, {value: 'paid', name: 'Оплачена'}, {
    name: 'Ожидает оплаты',
    value: 'wait'
}, {
    name: 'Проблемы с оплатой', value: 'reject'
}
// , {name: 'Архив', value: 'archived'}
]

function TransactionPreview(props) {
    let [item, setItem] = useState({...props.item})
    let [isDetails, setIsDetails] = useState(false)
    let {ind, onChange} = props;

    let opts = props.key ? {key: props.key} : {}

    let save = (scb, ecb) => {


        console.log("qqqqq gen history", item?.employee);
        let items = generateHistory([item])
        item = items[0];
        console.log('*........ ## teimmmmmmmm', item?.employee);
        if (item && item._id) {
            saveTransaction(item, (r) => {
                onHttpRes(r, scb, ecb)
            }, ecb)
        } else {
            createTransaction(item, (r) => {
                onHttpRes(r, scb, ecb)
            }, (e) => {
                console.log("qqqqq eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e);
                ecb && ecb()
            })
        }


    }

    let onHttpRes = (r, scb, ecb) => {
        item = {...item, ...r}
        scb && scb();
        onChange = onChange || window?.updateTransaction

        onChange && onChange(item)
        setItem(item)
        window.hideModal(props)
    }
    let cancel = (scb) => {
        scb && scb();
        window.hideModal(props)
    }

    let remove = (scb, ecb) => {
        let items = generateHistory([item])
        item = items[0];
        // item.status = 'archived'
        // onHttpRes(item, scb, ecb)
        // return;

        saveTransaction({...item, status: 'archived'}, r => {
            onHttpRes(r, scb, ecb)
        })
        console.log("qqqqq remove",);
    }
    let propsEdit = {
        item, onChange: v => setItem({...item, ...v}),
        isNew: props.isNew,
    }
    // console.log("qqqqq OPENNNNNNNNNNNNNNNNNNN", item);
    return <div className={'row'} {...opts}>

        <div className="col-xs-12">
            <hr/>
            {/*<TransactionPreview item={item}></TransactionPreview>*/}
        </div>
        <div className="col-xs-12">

            {!item._id && <Button onClick={save}>Создать</Button>}
            {!!item._id && <Button onClick={save}>Сохранить</Button>}
            <Button onClick={cancel} color={4}>Отмена</Button>
            <div className="ib favStars"  onClick={() => {
                item.isPeriodFavorite = !item.isPeriodFavorite;
                setItem({...item})
            }}>
                {item.isPeriodFavorite && <div className="fa fa-star"></div>}
                {!item.isPeriodFavorite && <div className="fa fa-star-o"></div>}
            </div>
            <div className="ib" style={{margin: '5px 0 0 10px'}}>
                {pubType(item.type)} #{item._id} <span style={{opacity: .4}}>[type = {item.type}]</span>
            </div>

        </div>
        <div className="col-xs-12">
            <hr/>
            <GeneralTr {...propsEdit}></GeneralTr>
            <hr/>
            {item.type == 'oklad' && <div className="row">
                <OkladEdit  {...propsEdit}></OkladEdit>
            </div>}
            {item.type == 'raspredi' && <div className="row">
                <RasprediEdit  {...propsEdit}></RasprediEdit>
            </div>}
            {item.type == 'perevod' && <div className="row">
                <PerevodEdit  {...propsEdit} isSmart={true}></PerevodEdit>
            </div>}
            {item.type == 'perevod_smart' && <div className="row">
                <PerevodEdit  {...propsEdit} isSmart={true}></PerevodEdit>
            </div>}
            {item.type == 'prihod' && <div className="row">
                <PrihodEdit {...propsEdit}></PrihodEdit>
            </div>}
            {item.type == 'trati' && <div className="row">
                <TratiEdit {...propsEdit}></TratiEdit>
            </div>}
            {item.type == 'nalog_office' && <div className="row">
                <NalogOfficeEdit {...propsEdit} ></NalogOfficeEdit>
            </div>}

            <div className="row">
                <hr/>
            </div>



            {isDetails && <>
                <a style={{marginLeft: '10px'}} onClick={() => {
                    setIsDetails(!isDetails)
                }}>Детали</a>
                {!!item._id && <a onClick={() => remove()} style={{marginLeft: '5px'}}>Удалить</a>}

                <Stringify item={item?.items?.at(-2)}></Stringify>
                <Stringify item={item?.items?.at(-1)}></Stringify>
                <Smart
                    obj={item}
                    defSize={3}
                    items={[// {key: 'isPeriodFavorite', name: 'isPeriodFavorite', type: 'checkbox'},
                        {type: 'select', key: 'type', items: window?.info?.types}, {
                            key: 'name',
                            name: 'name'
                        }, // {path: 'Main/MyAutocomplete', key: 'worker', itemsKey: 'employees', label: 'Кто билит'},
                        {
                            path: 'Main/MyAutocomplete',
                            key: 'employee',
                            itemsKey: 'employees',
                            label: 'Сотрудник'
                        }, {
                            path: 'Main/MyAutocomplete',
                            key: 'employeeShadow',
                            itemsKey: 'employees',
                            label: 'Кто Шэдоу'
                        }, {
                            path: 'Main/MyAutocomplete',
                            key: 'contract',
                            itemsKey: 'contracts',
                            label: 'Контракт'
                        }, {path: 'Main/MyAutocomplete', key: 'position', itemsKey: 'positions', label: 'Позиция'},

                        {size: 12, type: 'HR'}, {
                            name: 'special.shadow_perc',
                            key: 'special.shadow_perc'
                        }, {name: 'special.korp_perc', key: 'special.korp_perc'}, {
                            name: 'special.korp_fix',
                            key: 'special.korp_fix'
                        }, {size: 12, type: 'HR'},

                        {
                            path: 'Main/MyAutocomplete',
                            key: 'special.korp_team',
                            itemsKey: 'teams',
                            label: 'Корп налог тим'
                        },

                        {
                            path: 'Main/MyAutocomplete',
                            key: 'special.korp_account',
                            itemsKey: 'accounts',
                            label: 'Корп налог аккаунт'
                        },

                        {size: 12, type: 'HR'}, {
                            size: 6,
                            defSize: 6,
                            childs: [{size: 12, type: 'text', value: 'Спискание'}, {
                                path: 'Main/MyAutocomplete',
                                key: 'accountFrom',
                                itemsKey: 'accounts',
                                label: 'Аккаунт'
                            }, {
                                path: 'Main/MyAutocomplete',
                                key: 'teamFrom',
                                itemsKey: 'allTeams',
                                label: 'Команда'
                            }, {
                                key: 'amountFrom',
                                name: 'amountPlainFrom',
                                type: 'number',
                                size: 12
                            }, // {key: 'amountNalogFrom', name: 'amountNalogFrom', type: 'number', size: 6}
                            ]
                        }, {
                            size: 6,
                            defSize: 6,
                            childs: [{size: 12, type: 'text', value: 'Зачисление'}, {
                                path: 'Main/MyAutocomplete',
                                key: 'accountTo',
                                itemsKey: 'accounts',
                                label: 'Аккаунт'
                            }, {
                                path: 'Main/MyAutocomplete',
                                key: 'teamTo',
                                itemsKey: 'allTeams',
                                label: 'Команда'
                            }, {key: 'amountTo', name: 'amountTo', type: 'number', size: 12}]
                        },
                        {
                            path: 'Main/MyAutocomplete',
                            key: 'special.virtualAccount1',
                            itemsKey: 'accounts',
                            label: 'Виртуал Аккаунт (Смарт)',
                            size: 12,
                        },

                    ]}
                    onChange={(v) => {
                        console.log("qqqqq vvvvvvvv CHANGE", v);
                        setItem({...v})
                        // onChange && onChange(v)
                    }}
                ></Smart>
                <div>
                    AmountFrom: {item.amountFrom}
                    <div>
                        amountFromMonthly: {item.amountFromMonthly}
                    </div>
                    <div>
                        amountToMonthly: {item.amountToMonthly}
                    </div>
                    <div>
                        periodsCount: {item.periodsCount}
                    </div>
                    <div>
                        Кол-во плаин транзакций: {item?.items?.length}
                    </div>
                </div>


                <Stringify item={props}></Stringify></>}

            {!item._id && <Button onClick={save}>Создать</Button>}
            {item._id && <Button onClick={save}>Сохранить</Button>}
            <Button onClick={cancel} color={4}>Отмена</Button>
            <a style={{marginLeft: '10px'}} onClick={() => {
                setIsDetails(!isDetails)
            }}>Детали</a>
        </div>
    </div>
}


export default TransactionPreview
