import React, {lazy, useRef, useEffect, useState, Suspense} from 'react';
import _ from 'underscore';

let WS = {
    wsId: 0,
    signals: {},
    getID() {
        return ++WS.wsId;
    },
    subscribe(type, id, fn) {
        WS.signals[type] ??= {}
        WS.signals[type][id] = {fn}
    },
    unsubscribe(type, id) {
        WS.signals[type] ??= {}
        delete WS.signals[type][id]
    },
    emit(type, obj) {

        console.log("qqqqq emittttttttttttttttt", type, obj, WS.signals);
        _.each(WS.signals[type], ({fn}, ind) => {
            fn && fn(obj)
        })
    }
}

window.WS = WS;

function WSITEM() {
    const [ws, setWs] = useState(null);
    const reconnectInterval = useRef(null);

    useEffect(() => {
        connect();

        // Clean up on component unmount
        return () => {
            if (reconnectInterval.current) clearInterval(reconnectInterval.current);
            if (ws) ws.close();
        };
    }, []);

    const connect = () => {
        const socket = new WebSocket('ws://localhost:8080');

        socket.onopen = () => {
            console.log('Connected to the server');
            if (reconnectInterval.current) {
                clearInterval(reconnectInterval.current);
                reconnectInterval.current = null;
            }
        };

        socket.onmessage = (event) => {
            let data = JSON.parse(event.data)
            if (data.type === 'dialog') {
                WS.emit('dialog', data.item)
            } else if (data.type === 'message') {
                WS.emit('message', data.item)
            }
            console.log('SIGNAL::::::: ', data.type, data.item);
        };

        socket.onclose = () => {
            console.log('Disconnected from the server');
            // Attempt to reconnect every 3 seconds
            if (!reconnectInterval.current) {
                reconnectInterval.current = setInterval(() => {
                    console.log('Attempting to reconnect...');
                    connect();
                }, 3000);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error: ', error);
            socket.close();
        };

        setWs(socket);
    };

    const sendMessage = () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send('Hello, server!');
        } else {
            console.log('WebSocket is not connected.');
        }
    };

    return <></>
    return <button onClick={sendMessage}>Send Message</button>

}

export default WSITEM;
