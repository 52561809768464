import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


function Graphic(props) {
    const labels = props.labels || [];

    const data = {
        labels,

        datasets: props.datasets || [
            {
                label: 'Label 1',
                data: labels.map(() => 100 * Math.round(Math.random())),
                borderColor: 'rgb(53, 162, 235)',
                // backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ],
    };
    // const options = props.options || {
    //     responsive: true,
    //     scales: {
    //         y: {
    //             beginAtZero: true
    //         }
    //     }
    // };
    return <Line
        options={props.options || {}}
        data={data}
    />;
}

export default Graphic
