import React, {useState} from 'react';
import Smart from 'libs/Smart'
import Table from 'libs/Table'
import ComponentSearcher from "./ComponentSearcher";
import _ from "underscore";

function ComponentRender(params, p2) {
    let {props} = params || {}
    const [obj, setObj] = useState({});
    const [count, setCount] = useState(0);

    let {item, onAutosave} = props;
    return <div>
        {/*<div style={{marginBottom: '20px'}}>Конфигуратор дефолтных чаилдов</div>*/}
        <ComponentSearcher
            item={item}
            onClickItem={(point) => {
                console.log("qqqqqpoint 111", item.childs, props.item.childs);


                item.childs = item.childs || [];
                item.childs.push(point);
                item.childs = _.uniq(item.childs, v => {
                    return v ? v._id || v : null
                })
                props.onGlobalChange && props.onGlobalChange(item)
                // props.item.items.push(comp)
                // this.setState({cd: new Date()})
            }}
        ></ComponentSearcher>
        <Smart
            items={[
                {
                    sortable: true,
                    key: 'childs',
                    woAdd: true,
                    size: 12,
                    notFoundText: `Выберите чайлд компоненты для текущей родительской компоненты ${item.name}`,
                    each: [{
                        Component: (opts) =>{
                            let {localItem} = opts;
                            return <div>{localItem.name}</div>
                        }
                    }
                    // , {
                    //     name: 'name', key: 'value', size: 6
                    // }
                    ]
                },
            ]}
            obj={item}
            onChange={(v, _id) => {
                console.log("qqqqqon Change", v, _id);
                props.onGlobalChange && props.onGlobalChange(v)
            }
            }></Smart>

    </div>
}

export default ComponentRender
