import React, {lazy, useEffect, useState, Suspense} from "react";
import _ from 'underscore';
import Smart from 'libs/Smart';
import MyModal from 'libs/MyModal';
import DefOne from 'libs/DefOne';
import ExtracterDetailed from "../libs/Extracter/ExtracterDetailed";
import {pubHours, onChangePrice} from './methods/calcMethods';

import {
    Link
} from "react-router-dom";
import Input from "../libs/Input";
import Textarea from "../libs/Textarea/Textarea";

function FHours({obj, onChange, _id}) {
    return <div className={'ib'}><input type="text" placeholder={'FHours'}
                                            className={'fhours-input'}
                                          value={obj[_id].fhours} onChange={(e) => {
        obj[_id].fhours = e.target.value;
        obj[_id].selected = true;
        onChange && onChange(obj, _id)
    }}/></div>
}


function CompCustomization(props) {
    let {vars = {}, woCustom, woDetails, onQuestion, onChange, item} = props.props || props;
    vars.obj = vars.obj || {};
    // let obj = vars.obj || {};
    let obj = vars.obj;
    let childs = _.map(item.childs, it => {

        return global.compObj[it ? it._id || it : null]
    })
    // console.log("qqqqq props", props);

    const onClickIt = (_id) => {
        obj[_id] = typeof obj[_id] === 'object' ? obj[_id] : {}
        obj[_id].selected = !obj[_id].selected// = !obj[_id]
        if (obj[_id].selected && !obj[_id].type) {
            obj[_id].type = 'norm'
        }
        onChange && onChange(obj, _id)
    }

    let pointId = 1008;
    let pointItem = (global.compObj || {})[pointId];
    let titles = (key, name) => {
        name = name || ''
        let obj = {
            min: `Мин версия`,
            max: `Макс версия`,
            norm: `Норм версия`,
            custom: `Ручная кастомизация`,
            fhours: `Часы форсом (без пулла инфы)`,
            delta: `Добавить экстра часы`,
            count: `Кол-во`,
            comment: `Коммент`,
            edit: `Редактировать`,
            info: `Полная информация`,

        }
        return obj[key]
    }
    return <div className={'customization'}>
        {/*<div className="row rop-np">*/}
        {/*    {!woDetails && <div className="col-sm-3">*/}
        {/*        <Input placeholder={'DHours'} type={"number"} value={vars.delta_hours} onChange={(v) => {*/}
        {/*            vars.delta_hours = v;*/}
        {/*            onChange && onChange(obj, null, vars)*/}

        {/*            // onGlobalChange(item)*/}
        {/*            // onChangePrice(item, r => {*/}
        {/*            //     vars[varsKey] = r.vars[varsKey];*/}
        {/*            //     onGlobalChange(item)*/}
        {/*            // })*/}
        {/*        }}*/}
        {/*        ></Input>*/}
        {/*    </div>}*/}
        {/*    {!woDetails && <div className="col-sm-9">*/}
        {/*        <Textarea*/}
        {/*            minRows={1}*/}
        {/*            placeholder={`Details`}*/}
        {/*            value={vars.desc} onChange={(v) => {*/}
        {/*            vars.desc = v;*/}
        {/*            onChange && onChange(obj, null, vars)*/}


        {/*            // onGlobalChange(item)*/}
        {/*        }}></Textarea>*/}
        {/*    </div>}*/}

        {/*</div>*/}

        {(childs || []).map((it, ind) => {
            it = it || {}
            let _id = (it || {})._id || it;
            let customItem = global.compObj[_id] || {};
            obj[_id] = obj[_id] || {}
            let __obj = obj[_id]
            return (<div key={ind} className={'row '} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return true;
            }}>
                <div className={"col-sm-12 cust-child " + (!__obj.selected ? 'child-non-selected' : '')}>
                    <div className="ib">
                        <div className="ib child-checkbox">
                            <div className="checkbox custom-checkbox-wrap" onClick={(e) => {
                                onClickIt(_id)

                                // e.preventDefault()
                                e.stopPropagation()
                                return true;
                            }}>
                                <input className="custom-checkbox" type="checkbox" checked={!!(obj[_id] || {}).selected}
                                       />
                                    <label htmlFor="color-1"></label>
                            </div>


                            {/*<input type="checkbox"*/}
                            {/*       checked={!!(obj[_id] || {}).selected}*/}
                            {/*       style={{width: '20px'}}*/}
                            {/*       className={'form-control custom-checkbox'}*/}
                            {/*       onClick={(e) => {*/}
                            {/*           onClickIt(_id)*/}

                            {/*           // e.preventDefault()*/}
                            {/*           e.stopPropagation()*/}
                            {/*           return true;*/}
                            {/*       }}*/}
                            {/*       onChange={() => {*/}

                            {/*       }}*/}
                            {/*/>*/}
                        </div>

                        <div className="ib child-name">
                            <b onClick={(e) => {
                                onClickIt(_id)

                                // e.preventDefault()
                                e.stopPropagation()
                                return true;
                            }}>{(it || {}).name}</b>
                            {__obj.selected && <div className={'hours'}>
                                {(true || (__obj.type !== 'fhours')) && <div>
                                    <small>{pubHours(obj[_id]._hours)}</small>


                                </div>}
                            </div>}
                        </div>
                    </div>
                    <div className="ib cust-details" onDoubleClick={() => {
                    }}>

                        <div className={'ib ml-10 comp-icons-block'}>
                            {(['Min', 'Norm', 'Max', woCustom || !((customItem || {}).childs || []).length ? '' : 'Custom', 'FHours',] || []).map((it, ind) => {
                                if (!it) {
                                    return <span></span>
                                }

                                let key = it.toLowerCase();
                                obj[_id] = obj[_id] || {};


                                return (
                                    <span
                                        title={titles(key)}
                                        className={'comp-vars ' + (obj[_id].type === key ? 'active' : '')}
                                          key={ind}
                                          onClick={() => {
                                              if (key === 'fhours' && __obj.type === 'fhours') {
                                                  key = 'norm'
                                              }
                                              obj[_id].type = key;
                                              obj[_id].selected = true;
                                              onChange && onChange(obj, _id)
                                          }}>
                                {/*{it}*/}
                                        <img src={"/icons/" + key + '.svg'} data-key={key} alt=""
                                             className={'comp-img'}/>
                            </span>)
                            })}
                            {(__obj.type === 'fhours') && <FHours obj={obj} onChange={onChange} _id={_id}></FHours>}


                            <span
                            title={titles('delta')}
                                className={'comp-vars ' + (__obj.isDelta ? 'active' : '')}
                                  style={{marginLeft: '20px'}}
                                  onClick={() => {
                                      __obj.isDelta = !__obj.isDelta;
                                      __obj.delta_hours = 0;
                                      __obj.selected = true;
                                      onChange && onChange(obj, _id)
                                  }}>
                                <img className={'comp-img'} data-key={'delta'} src={"/icons/delta.svg"} alt=""/>
                            </span>
                            {(__obj.isDelta) && <div className={"ib " }>
                                <input placeholder={'DHours'}
                                       className={'delta-input ' +  ( (__obj.delta_hours ? '' : 'passive22'))}
                                       type={"number"} value={__obj.delta_hours} onChange={(e) => {
                                    __obj.delta_hours = e.target.value;
                                    __obj.isDelta = true;
                                    __obj.selected = true;
                                    onChange && onChange(obj, null, vars)

                                    // onGlobalChange(item)
                                    // onChangePrice(item, r => {
                                    //     vars[varsKey] = r.vars[varsKey];
                                    //     onGlobalChange(item)
                                    // })
                                }}
                                ></input>
                            </div>}


                            <span className={'comp-vars ' + (__obj.isCount ? 'active' : '')}
                                  title={titles('count')}

                                  style={{marginLeft: '20px'}}
                                  onClick={() => {
                                      __obj.isCount = !__obj.isCount;
                                      __obj.count = 1;
                                      __obj.selected = true;
                                      onChange && onChange(obj, _id)
                                  }}>
                                <img className={'comp-img'} data-key={'delta'} src={"/icons/count.svg"} alt=""/>
                            </span>
                            {(__obj.isCount) && <div className={"ib " }>
                                <input placeholder={'Count'}
                                       className={'delta-input ' +  ( (__obj.count ? '' : 'passive22'))}
                                       type={"number"} value={__obj.count} onChange={(e) => {
                                    __obj.count = e.target.value;
                                    __obj.isCount = true;
                                    __obj.selected = true;
                                    onChange && onChange(obj, null, vars)

                                    // onGlobalChange(item)
                                    // onChangePrice(item, r => {
                                    //     vars[varsKey] = r.vars[varsKey];
                                    //     onGlobalChange(item)
                                    // })
                                }}
                                ></input>
                            </div>}



                        </div>

                        <span
                            title={titles('comment')}
                            onClick={() => {
                            __obj.isComment = !__obj.isComment;
                            onChange && onChange(obj, _id)
                        }} className={'comp-vars ' + (__obj.isComment ? 'active' : '')}>
                                <img className={'comp-img'} data-key={'comment'} src={"/icons/comment.svg"} alt=""/>
                            </span>
                        <Link
                            title={titles('edit')}

                            to={'/components/' + it._id}
                              style={{padding: '5px', marginTop: '-3px', marginRight: '-8px'}}
                              className={'comp-vars'}
                              onClick={(e) => {
                                  // e.preventDefault();
                                  // e.stopPropagation();
                                  // return true;
                              }}>
                            <img src="/icons/edit.svg" alt=""/>
                        </Link>
                        <i
                            title={titles('info')}

                            className={'comp-vars'}
                           style={{padding: '5px', marginTop: '-3px'}}
                           onClick={(e) => {
                               console.log("qqqqq question ",);
                               onQuestion && onQuestion(_id)
                               // e.preventDefault();
                               // e.stopPropagation();
                               // return true;
                           }}>
                            <img src="/icons/info.svg" alt=""/>
                        </i>

                        {/*{it.selected ? 'YES' : 'NO'}*/}
                        {__obj.selected && <div className="w100">
                            {__obj.isComment && <div className="w100">
                                <Textarea
                                    minRows={1}
                                    placeholder={`Details`}
                                    woLabel={true}
                                    value={vars.desc} onChange={(v) => {
                                    vars.desc = v;
                                    onChange && onChange(obj, null, vars)


                                    // onGlobalChange(item)
                                }}></Textarea>
                            </div>}
                        </div>}
                    </div>

                </div>

                {!!(obj[_id] || {}).selected && <div>
                    {/*{obj[_id].type !== 'fhours' && <div>*/}
                    {/*    <small>{pubHours(obj[_id]._hours)}</small>*/}


                    {/*</div>}*/}
                    {obj[_id].type === 'custom' && <div className={'custom-child w100'}>
                        <CompCustomization
                            // woCustom={true}
                            item={customItem}
                            onQuestion={onQuestion}
                            childs={customItem.childs}
                            vars={obj[_id].custom || {}}
                            onChange={(_obj, __id, _vars) => {
                                console.log("qqqqq _vars", _obj, _vars, JSON.stringify(_vars, null, 2));
                                obj[_id].custom = obj[_id].custom || {};

                                if (_vars) {
                                    obj[_id].custom.delta_hours = _vars.delta_hours;
                                    obj[_id].custom.desc = _vars.desc;
                                }

                                obj[_id].custom.obj = _obj || {};

                                onChange && onChange(obj)
                            }}></CompCustomization>
                    </div>}

                </div>}
            </div>)
        })}
    </div>
}

export default CompCustomization