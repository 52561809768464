import MyModal from "../libs/MyModal";
import {pubHours} from "./methods/calcMethods";
import CompCustomization from "./CompCustomization";
import React from "react";

function QuestionComponent (props) {
    // const [pointItem, setPointItem]
    let {onChange, _id} = props;
    let pointItem = (global.compObj || {})[_id];

    return (pointItem ? <MyModal
        isOpen={!!pointItem}
        size={'full'}
        title={`Component ${pointItem.name}`}
        onClose={() => {
            onChange && onChange(-1)
        }
        }
    >

        <div className={'preventClick'}>
            {(['Min', 'Norm', 'Max']).map((_key, ind) => {
                let key = _key.toLowerCase();
                let vars = ((pointItem.vars || {})[key] || {})

                return (<div key={ind}>
                    <strong>{_key} {pubHours(vars.hours)}</strong>
                    <CompCustomization
                        item={pointItem}
                        vars={vars}
                        onQuestion={(_id) => {
                            // setPointId(_id)
                            onChange(_id)
                        }}
                        onChange={(obj, _id) => {
                            // console.log("qqqqq on change", obj);
                            // vars[varsKey].obj = obj;
                            // onGlobalChange(item);
                            //
                            // onChangePrice(item, r => {
                            //     vars[varsKey] = r.vars[varsKey];
                            //     onGlobalChange(item)
                            // })
                        }}></CompCustomization>


                    <hr/>
                </div>)

            })}
        </div>

    </MyModal> : <div></div>)
}

export default QuestionComponent
