global.is_local = /localhost/.test(window.location.host) ? 1 : 0;

let domain = global.is_local ? 'http://localhost:6172' : 'https://finapi.itrum.ru';
domain = 'https://finapi.itrum.ru';

window.env = {
    // domain     : ,
    domain,
    FILE_DOMAIN: domain,
    title: 'Fin Portal',
    login_title: 'Fin Portal',
    wo_token: false,
    redirect_after_login: '/admin/users'

}

document.title = window.env.title;
