import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import Input from "../../libs/Input";
import {generateHistory, pubAccount, pubEmployee, pubTeam} from "./methods";
import {toArr, toGroup} from "./GroupRender";
import {round, ToRub} from "./TopStats";
import MyAutoComplete from "./MyAutocomplete";
import {AddBtn} from "./Main";
import {calcTransactions} from "./UserPeriodPreview";
import {AmountEdit} from "./Tr/OkladEdit";
import MyModal from "../../libs/MyModal";


function Layout2(props) {
    let {teams, period, transactions, onChange} = props;
    let [gg, setGg] = useState({})
    let [isOpen, setIsOpen] = useState(false)
    let [teamsTotal, setTeamsTotal] = useState({})
    // let [info, setInfo] = useState({})

    if (!teams.length) {
        teams = window.info?.teams?.map(it => it._id)
    }


    console.log('*........ ## ROOT RENDER', props);
    let addOfficeUser = (scb, v) => {
        scb && scb()
        window?.openMainModal({
            type: 'transaction', item: {...v || {}, type: 'nalog_office',}
        })
    }

    useEffect(() => {
        calc()
    }, [JSON.stringify(transactions)])

    // useEffect(() => {
    //
    // }, [JSON.stringify(gg)])

    function calc() {
        let gg = toGroup(transactions.filter(it => it.type == 'nalog_office'), ['teamTo', 'employee'])
        setGg(gg)
    }

    function calcInfo() {
        let info = {};
        _.each(gg, (item, teamId) => {
            info[teamId] ??= {k: 0}

            _.each(gg[teamId], (arr, employeeId) => {

                _.each(arr, (item, ind) => {
                    let k = +item?.special?.force_korp_k || +item?.special?.korp_k || 1;
                    info[teamId][employeeId] ??= {k: 0}

                    info[teamId].k += k
                    info[teamId][employeeId].k += k
                })
            })
        })
        return info;
    }

    let recalc = (scb, teamId) => {
        scb && scb()
        let items = [];
        _.each(gg[teamId], (arr, employee) => {
            _.each(arr, (item, ind) => {
                items.push(item)
            })
        })

        items = generateHistory(items)

        global.http.post('/multi-save-plain',
            {
                items: items,
                filter: {period, type: 'nalog_office'}
            }).then(r => {
            console.log("qqqqq multi save completeed", r);
            onChange && onChange(r.items)
        })
    }

    let setProportinal = (scb, valuePer1, teamId) => {
        if (!valuePer1) {
            scb && scb()
            return alert('Установите сумму налога')
        }
        // let virtaulAccount = getVirtaulAccount()
        _.each(gg[teamId], (arr, employeeId) => {

            let k = 1 / (arr.length || 10000);
            console.log("qqqqq item4444444", k, arr);
            _.each(arr, (item, ind) => {
                item.special ??= {}
                item.special.korp_k = item.special.force_korp_k || k;
                item.amountFrom = round(((+item?.special?.korp_k || 1) * (valuePer1 || 0)))
                item.amountTo = round(((+item?.special?.korp_k || 1) * (valuePer1 || 0)))

            })
        })

        setGg({...gg})
        recalc(scb, teamId);

    }

    let getTotalStats = (teamId) => {
        return Math.abs(((window.totalStats || {})[teamId] || {})['calc_only.trati']?.total)
    }
    let info = calcInfo();
    console.log("qqqqq infoooooooooooooooooooooooooo44", info[1002]);
    // window.gg = gg;
    // let gg =


    let officeTeams = teams.map(teamId => {
        return (window?.info?.teamsObj || {})[teamId] || {};//it.type == 'office'

    }).filter(it => it.type === 'office')

    console.log("qqqqq gggggg", gg, transactions);
    if (!officeTeams?.length) {
        return null;
    }
    return <>
        <a onClick={() => {
            setIsOpen(true)
        }}>Оффис взносы</a>
        <MyModal
            size={'full'}
            isOpen={isOpen} onClose={() => setIsOpen(false)}>
            {!!officeTeams?.length && <div>Установка коэффцициентов для офисных команд</div>}
            {!officeTeams?.length && <div>Нет активных оффисных команд для установки коээфцициентов</div>}

            <hr/>
            {(officeTeams || []).map((team, ind) => {
                let teamId = team?._id
                let {type} = team;
                if (type !== 'office') {
                    return null
                    // return <div>'not office type, _id = {teamId}</div>
                }
                let {total, positive, negative} = calcTransactions(transactions, (it, tr) => {
                    return tr.amount > 0 && it.team == teamId;
                });

                let itemsObj = gg[teamId] || {};
                let totalK = round((info[teamId] || {}).k || 1, 1);
                let totalAmount = teamsTotal[teamId];///total;
                let totalAmountPer1 = round((totalAmount / (totalK || 1)))//.toFixed(3);

                console.log("qqqqq itemsObjitemsObj", itemsObj, gg);
                return (<div key={ind} className={'row'}>
                    <div className="col-xs-4">
                        <div></div>
                        {pubTeam(teamId)}

                        {/*{totalAmountPer1}*/}
                        <div>
                        </div>

                        {/*<div>*/}
                        {/*    <Button onClick={(scb) => {*/}
                        {/*        addOfficeUser(scb, {teamTo: teamId})*/}
                        {/*    }}>Add Office User</Button>*/}
                        {/*</div>*/}
                        <div>
                            <div>
                                <AddBtn name={'Оффис Юзер'} type={'nalog_office'} isNew={true} def={{teamTo: teamId}}></AddBtn>
                            </div>
                            {/*<hr/>*/}
                            <div style={{marginTop: '10px'}}>
                                Тотал офис траты этой команды:
                                <AmountEdit value={teamsTotal[teamId]} onChange={v => {
                                    setTeamsTotal({...teamsTotal || {}, [teamId]: v})
                                }}></AmountEdit>
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <strong>Тотал на сотрудника: <ToRub woPull={true} k={-1}>{totalAmountPer1}</ToRub></strong>*/}
                        {/*</div>*/}
                        <Button
                            color={4}
                            size={'xs'}
                            onClick={(scb) => {
                                setProportinal(scb, totalAmountPer1, teamId)
                            }}>Установить суммы</Button>

                        {/*<Button onClick={(scb) => {*/}
                        {/*    recalc(scb, teamId)*/}
                        {/*}}>Пересчет</Button>*/}
                    </div>
                    <div className="col-xs-8">
                        {(Object.keys(itemsObj) || []).map((employee, ind) => {
                            let eTransactions = itemsObj[employee]
                            console.log("qqqqq vvvvvvvvvvvvvvvvvvvvvvvvvvvv", eTransactions);
                            return <div className={'row'}>
                                <div className="col-xs-3">
                                    <div className="ellipse">
                                        {pubEmployee(employee)}
                                    </div>
                                    <div style={{marginTop: '-10px'}}>
                                        <small>
                                            x{round(((info[teamId] || {})[employee] || {}).k, 1)}
                                        </small>
                                    </div>
                                </div>

                                <div className={'col-xs-9'}>
                                    {(eTransactions || []).map((it, ind) => {
                                        // if (it.teamFrom != teamId || it.type != 'nalog_office') {
                                        //     return;
                                        // }

                                        return (<div key={ind} className={'row'}>
                                            <div className="col-xs-2">
                                                <ToRub k={-1} woPull={true}>{it.amountFrom}</ToRub>
                                            </div>
                                            <div className="col-xs-2">

                                                <div className="ib">
                                                    <Input type="number" value={it.special?.force_korp_k}
                                                           onChange={v => {
                                                               it.special = it.special || {}
                                                               it.special.force_korp_k = v;
                                                               it.special.korp_k = 1;
                                                               setGg({...gg})
                                                           }}></Input>
                                                </div>
                                            </div>
                                            <div className="col-xs-3">
                                                {pubTeam(it.teamFrom)}
                                                <div>
                                                    <small>{pubAccount(it.accountFrom)}</small>
                                                </div>
                                            </div>
                                            <div className="col-xs-3">
                                                {pubTeam(it.teamTo)}
                                                <div>
                                                    <small>{pubAccount(it.accountTo)}</small>
                                                </div>
                                            </div>
                                            <div className="col-xs-2">
                                                <div
                                                    className={'ellipse'}
                                                    style={{marginTop: '-5px', display: 'inline-block', float: 'left'}}
                                                >

                                                    <small
                                                        onClick={() => {
                                                            window.openMainModal({
                                                                type: 'transaction',
                                                                item: it
                                                            })
                                                        }}><a>Детали</a></small>
                                                </div>
                                            </div>
                                            {/*<div className="col-xs-2">*/}
                                            {/*    <ToRub>{it.amountTo}</ToRub>*/}
                                            {/*</div>*/}


                                        </div>)

                                    })}</div>
                            </div>
                        })}
                    </div>
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                </div>)
            })}
        </MyModal>
    </>
}

export default Layout2
