import React, {useRef, useState, useEffect} from 'react';
import MyModal from "../../libs/MyModal";
import Stringify from "../Stringify";
import _ from "underscore";
import UserPeriodPreview from './UserPeriodPreview';

function Layout2(props) {
    let [isOpen, setIsOpen] = useState(true)
    let [originalItem, setOriginalItems] = useState(props.item)

    let {onChange, periodStart, periodEnd, period, items, employeeId} = props;
    periodStart = periodStart || period
    periodEnd = periodEnd || period

    function getPeriodsArr(start, end) {
        let periods = [];
        let currentDate = new Date(start + '-01'); // Initialize with the first day of the start month

        const endDate = new Date(end + '-01'); // Initialize with the first day of the end month

        while (currentDate <= endDate) {
            // Format the date as 'YYYY-MM'
            let year = currentDate.getFullYear();
            let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Pad month with zero if needed
            periods.push({period: `${year}-${month}`});

            // Increment the month
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return periods.reverse();
        // return [{period}]
    }


    useEffect(() => {
        console.log("qqqqq isOpen", isOpen);
        if (!isOpen) {
            window.hideModal(props)
        }
    }, [isOpen])

    let periods = getPeriodsArr(periodStart, periodEnd)
    console.log("qqqqq periods", periods);
    return <div>
        <MyModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            User Modal {periodStart} {periodEnd}

            {(periods || []).map((it, ind) => {
                return <UserPeriodPreview
                    period={it.period}
                    items={items}
                    ind={ind}
                    employeeId={employeeId}
                    isEdit={true}
                    onComplete={() => {
                        setIsOpen(false)
                    }}
                    onChange={(item) => {
                        onChange(item)
                    }}
                ></UserPeriodPreview>
            })
            }
            <Stringify item={props}></Stringify>


        </MyModal>
    </div>
}


export function smartInd(props) {
    let query = {};
    if (props?.ind || props?.ind == 0) {
        query.ind = props.ind;
    }
    return query;
}


export default Layout2
