import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import MyAutoComplete from "../MyAutocomplete";
import Input from "../../../libs/Input";
import {PremiaEdit} from "../UserPeriodPreview";
import Button from "../../../libs/Button";
import {ToRub} from "../TopStats";
import Select from "../../../libs/Select";
import {toRub} from "../../methods/GenerateHistory";


function OkladEdit(props) {

    let {item, onChange} = props;
    item.special ??= {}
    item.special.dbItems ??= {}
    let special = item.special;
    let {dbItems} = item.special;
    dbItems.oklad_nalog ??= {}

    return <>
        <div className="col-xs-4">
            <MyAutoComplete localItem={item} field={{key: 'employee', itemsKey: 'employees'}}
                            label={'Сотрудник'}
                            onChange={employee => {
                                onChange({employee})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4">
            <MyAutoComplete localItem={item} field={{key: 'teamFrom', itemsKey: 'allTeams'}}
                            label={'Команда'}
                            onChange={teamFrom => {
                onChange({teamFrom})
            }}></MyAutoComplete>
        </div>

        <div className="col-xs-4">
            <MyAutoComplete localItem={item} field={{key: 'position', itemsKey: 'positions'}}
                            label={'Позиция'}
                            onChange={position => {
                                onChange({position})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4">
            <MyAutoComplete localItem={item} field={{key: 'accountFrom', itemsKey: 'accounts'}}
                            label={'Аккаунт'}
                            onChange={accountFrom => {
                                onChange({accountFrom})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4">

            <AmountEdit
                accountId={item.accountFrom}
                label={'Сумма'} value={item.amountFrom} onChange={amountFrom => {
                onChange({amountFrom})

            }}/>
        </div>
        <div className="col-xs-4">
            <NalogEdit label={'Налог'} value={item.nalog_k} onChange={v => {
                item.nalog_k = v;
                onChange({special})
            }}/>
        </div>


        <div className="col-xs-12"></div>
        <PremiaEdit field={'premia'} dbItems={dbItems} it={item}
                    onChange={(v) => {
                        // special.dbItems = dbItems;
                        onChange && onChange({special})
                        console.log("qqqqq on Change44444444444444444444", special);
                    }}></PremiaEdit>
        {<div className={'col-xs-12'} style={{marginBottom: '10px'}}>
            <label htmlFor="">&nbsp;</label>
            <div>
                <Button color={4} size={'xs'} onClick={(e) => {
                    e && e()
                    special.isYearPremia = !special.isYearPremia;
                    onChange({special})
                }}>Годовая премия {special.isYearPremia && <>(скрыть)</>}
                </Button>
            </div>
            {/*{it.special.isYearPremia ? 'YES' : 'NO'}*/}
        </div>}
        <div className="col-xs-12"></div>

            {special.isYearPremia && <div className="col-xs-4">
                <Input
                    placeholder={'Период годовой премии (мес)'}
                    value={special?.yearPremiaPeriodTo}
                    onChange={v => {
                        special.yearPremiaPeriodTo = v;
                        special.isYearPremia = !special.isYearPremia;
                        onChange({special})
                    }}/>
            </div>}

        <div className="col-xs-12">
        </div>
        {special.isYearPremia && <PremiaEdit field={'year_premia'} dbItems={dbItems} it={item}
                                             onChange={() => {
                                                 onChange && onChange({special})
                                             }}></PremiaEdit>}
    </>
}

export function AmountEdit(props) {
    let {value, onChange, accountId, periodId, item, localItem, accountKey} = props
    periodId = periodId || window?.curPeriod || ''
    accountId = accountId || (localItem || item || {})[accountKey] || ''
    accountKey && console.log("qqqqq keeeeeeeeeeeeeeeeeeeeeeeeeee",props );

    let k = window.user.get_rub_k();
    return <>
        {/*<ToRub k={props.k || -1} postfix={' ₽'}>{value}</ToRub>*/}
        {/*{accountId} {periodId} {value}*/}
        <ToRub k={props.k || -1} postfix={' ₽'}>{toRub(value, accountId, periodId)}</ToRub>
        <Input {...props} value={+props.value * k || props.value} type={'number'} onChange={(v, a, b) => {
            console.log("qqqqq v, a, b", v, a, b);
            props.onChange(((+v) / k) || v)
        }}/>
    </>
}
export function NalogEdit(props) {
    let {value, onChange} = props
    return <>
        <Select
            title={props?.label || props?.name || "Налог"}
            items={[{name: 'Нет', value: 'no'}, {name: 'ООО', value: 'ooo'}, {name: 'НДФЛ', value: 'ndfl'}, ]}
            value={value}
            onChange={onChange}
        ></Select>
        {/*<ToRub k={props.k || -1} postfix={' ₽'}>{value}</ToRub>*/}
        {/*<ToRub k={props.k || -1}>{value}</ToRub>*/}

        {/*<Input {...props}/>*/}
    </>
}

export default OkladEdit
