import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import '../Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../../libs/Input";
import {getDefPeriod, getFullPeriods, incPeriod} from "../PeriodsSelector";
import Stringify from "../../Stringify";
import MyAutoComplete from "../MyAutocomplete";
import ObototStats from "./OborotStats";
import MyModal from "../../../libs/MyModal";
import {FilteredTransactions} from "../TopStats";
import {pubEmployee, pubTeam} from "../methods";
import Select from "../../../libs/Select";
import {isGoodRole} from "../../../App";

function generatePeriods(startPeriod, numPeriods) {
    const periods = [];
    let currentDate = new Date(startPeriod);

    for (let i = 0; i < numPeriods; i++) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
        periods.push({name: `${year}-${month.toString().padStart(2, '0')}`, year, month});
        currentDate.setMonth(currentDate.getMonth() - 1); // Incrementing month by 1
    }

    return periods;
}


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [selectedInd, setSelectedInd] = useState(0)
    let [filter, setFilter] = useState({teams: [1002]})
    let [filteredItems, setFilteredItems] = useState(null)
    let [transactions, setTransactions] = useState([])
    let [transactionsPeriods, setTransactionsPeriods] = useState({})
    let [periods, setPeriods] = useState(generatePeriods(getDefPeriod(), 24))
    let period = incPeriod(getDefPeriod(), -24);

    let teamsObj = filter.teams && filter.teams.length ? (filter.teams || []).reduce((acc, it) => {
        return {...acc, [it]: true}
    }, {}) : {all: true}

    let m = global.m;

    let openIds = (_ids) => {
        console.log("qqqqq OPEN IDS", _ids);
        let items = transactions.filter(it => {
            return (_ids || {})[it._id]
        })
        setFilteredItems(items)
    }
    useEffect(() => {
        global.http.get("/my-transaction", {per_page: 100000, filter: {status: {$ne: 'archived'}, period: {$gte: period}}}).then(r => {
            setTransactions(r.items)
            setTransactionsPeriods(_.groupBy(r.items, 'period'))
        })
    }, [])

    let isFilter = (it, item) => {
        console.log("qqqqq itttt", it?.team, teamsObj);
        return teamsObj.all || teamsObj[it?.team]
    }

    let defReports = {
        onOpen: openIds,
        periods,
        obj: transactionsPeriods,
        items: transactions,
    }
    let reports = [{
        ...defReports,
        title: 'По Пользователям',
        pubName: pubEmployee,
        groupFn: (it, item) => {
            if (!isFilter(it)) {
                return []
            }
            return [it.employee || 0]
            // return []
        },

    }, {
        ...defReports,
        title: 'По командам',
        pubName: pubTeam,
        groupFn: (it, item) => {
            if (!isFilter(it)) {
                return []
            }
            return [it.team || 0]
            // return []
        },
    }, {
        ...defReports,
        title: 'Тотал',
        groupFn: (it, item) => {
            if (!isFilter(it)) {
                return []
            }
            return ['oborot']
        },
        graphics:
            [
                [{key: 'oborot', name: 'Оборот Тотал', subKey: 'total'}],
                [{key: 'oborot', name: 'Оборот Накопит', subKey: 'final'}],
                [{key: 'oborot', name: '%', subKey: 'perc'}],
            ],
        tableFields: [
            {name: 'Финал', key: 'oborot', renderType: 'final'},
            {name: 'Тотал', key: 'oborot', renderType: 'total'},
            {name: '%', key: 'oborot', renderType: 'perc'},
        ]
    }]

    let repItem = reports[selectedInd || 0];
    if (!isGoodRole('topStats')) {
        return <>You don't have permissions</>
    }
    // let employees = transactions.
    console.log("qqqqq transactions511111111111", repItem, selectedInd);
    return <div className={'row'}>
        <div className="col-xs-12">
        <MyAutoComplete localItem={filter} field={{isMulti: true, key: 'teams', itemsKey: 'teams'}} onChange={v => {
            setFilter({teams: v})
            console.log("qqqqq vvvvvvvvvvv", v);
        }}></MyAutoComplete>
        <MyModal
            isOpen={filteredItems}
            onClose={() => setFilteredItems(false)}
        >
            <FilteredTransactions items={filteredItems}></FilteredTransactions>
        </MyModal>

        {/*<ObototStats*/}
        {/*    title={'Блок1'}*/}
        {/*    onOpen={openIds}*/}
        {/*    periods={periods} obj={transactionsPeriods} items={transactions}*/}
        {/*             groupFn={(it, item) => {*/}
        {/*                 return ['oborot']*/}
        {/*             }}*/}
        {/*             graphics={*/}
        {/*                 [*/}
        {/*                     [{key: 'oborot', name: 'Оборот Тотал', subKey: 'total'}],*/}
        {/*                     [{key: 'oborot', name: 'Оборот Накопит', subKey: 'final'}],*/}
        {/*                     [{key: 'oborot', name: '%', subKey: 'perc'}],*/}
        {/*                 ]*/}
        {/*             }*/}
        {/*             tableFields={[*/}
        {/*                 {name: '222', key: 'oborot', renderType: 'final'},*/}
        {/*                 {name: '222', key: 'oborot', renderType: 'total'},*/}
        {/*                 {name: '222', key: 'oborot', renderType: 'perc'},*/}
        {/*             ]}*/}

        {/*></ObototStats>*/}
        <Select items={reports.map((it, ind) => {
            return {name: it.title || '-', _id: ind + 1}
        })}
                value={selectedInd + 1}
                onChange={v => {
                    console.log("qqqqq ",);
                    setSelectedInd(v - 1)
                }}
        ></Select>
        <ObototStats
            {...repItem}
        ></ObototStats>
        </div>
    </div>
}

export default Layout2
