import React, {lazy, useEffect, useState, Suspense} from "react";
import Smart from 'libs/Smart';
import Textarea from 'libs/Textarea';
import Input from 'libs/Input';
import CompCustomization from './CompCustomization';
import {pubHours, onChangePrice} from './methods/calcMethods';
import MyModal from "../libs/MyModal";
import QuestionComponent from "./QuestionComponent";


function CompVars({props}) {
    let {item, selectedInd, onGlobalChange,} = props;
    let [varsKey, setVarsKey] = useState('norm')
    let [pointId, setPointId] = useState(-1)


    item.vars = item.vars || {}
    item.vars[varsKey] = item.vars[varsKey] || {}
    let vars = item.vars;
    // let pointItem = (global.compObj || {})[pointId]
    console.log("qqqqq pointItempointItempointItem", pointId);
    return <div>
        <QuestionComponent _id={pointId} onChange={setPointId}></QuestionComponent>
        {(['Min', 'Norm', 'Max'] || []).map((it, ind) => {
            let key = it.toLowerCase();

            return (<span
            title={it}
                className={'comp-vars ' + (varsKey === key ? 'active' : '')} key={ind} onClick={() => {
                setVarsKey(key)
            }}>
                <img src={"/icons/" + key + ".svg"} alt=""/>
            </span>)
        })}


        <div className={'mt-10 pub-hours'}>
            {pubHours(vars[varsKey].hours)}
            <div className="ib" style={{marginLeft: '30px'}}>
                <div>
            <span className={"comp-vars " + (vars[varsKey].delta_hours ? 'active' : '')}>
                <img className="comp-img" data-key="delta" src="/icons/delta.svg"
                     alt=""/>
            </span>
                    <input
                        key={varsKey}
                        type="number" placeholder={'Delta Hours'} className={'delta-input'}
                           value={vars[varsKey].delta_hours}
                           onChange={(e) => {
                               vars[varsKey].isDelta = true;
                               vars[varsKey].delta_hours = e.target.value;
                               onGlobalChange(item);

                               onChangePrice(item, r => {
                                   vars[varsKey] = r.vars[varsKey];
                                   onGlobalChange(item)
                               })
                           }
                           }/>

                </div>
            </div>
        </div>

        <hr/>


        {/*<hr/>*/}

        <CompCustomization
            item={item}
            vars={((vars || {})[varsKey] || {})}
            onQuestion={(_id) => {
                setPointId(_id)
            }}
            onChange={(obj, _id, _vars) => {
                console.log("qqqqq customItem obj TOP LEVEL", obj, _vars);

                if (_vars) {
                    vars[varsKey] = _vars
                } else {
                    vars[varsKey].obj = obj;
                }
                onGlobalChange(item);

                onChangePrice(item, r => {
                    vars[varsKey] = r.vars[varsKey];
                    onGlobalChange(item)
                })
            }}></CompCustomization>
        {/*<hr/>*/}
        {/*<pre>{JSON.stringify(vars[varsKey], null, 2)}</pre>*/}

    </div>
}

export default CompVars