import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import {paidStatuses} from "../TransactionEdit";
import Stringify from "../../Stringify";
import Input from "../../../libs/Input";
import {getFullPeriods, incPeriod} from "../PeriodsSelector";
import MyAutoComplete from "../MyAutocomplete";


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);

    let {item, onChange} = props;


    return <div>
        <Smart
            obj={item}
            onChange={onChange}
            items={[
                {
                    name: 'Статус', key: 'status', field: 'status', type: 'group', items: paidStatuses, size: 6
                }, {
                    key: 'comment',
                    name: 'Коммент',
                    type: 'textarea',
                    minRows: 1,
                    size: 6
                },
                {size: 12, type: 'HR', value: ' '},
                {key: 'date', name: 'Дата', type: 'date'},
                {key: 'period', name: 'Период', Component: PeriodPlusSel},
                { key: 'periodFrom', name: 'Период Нач',  Component: PeriodPlusSel},
                { key: 'periodTo', name: 'Период Оконч',  Component: PeriodPlusSel},

            ]}
        >

        </Smart>
    </div>
}


function PeriodPlusSel(props) {

    let {localItem, field} = props;
    let key = field.key;
    let values = ['-12', '-6', '-3', '-2', '-1', '0', 1, 2, 3, 6, 12 ]
    let periods = getFullPeriods(5, -5)
    return <div className={'periodSelPlus'}>
        <div className="pull-rigth">

        </div>
        <small className={'inputlabel'}>{field.name}</small>
        <MyAutoComplete
            items={periods}
            value={localItem[key]}
            onChange={v => {
                localItem[key] = v;
                props.onChange(key, localItem)
            }}
        ></MyAutoComplete>
        {/*<Input placeholder={field.name} woLabel={true} value={localItem[key]} onChange={(v) => {*/}
        {/*    localItem[key] = v;*/}
        {/*    props.onChange(key, localItem)*/}
        {/*}}></Input>*/}
        {(values || []).map((it, ind) => {
            return (<a key={ind} className={'ib periodPlusMinus'} onClick={v => {
                let period = localItem.period;
                let newPeriod = incPeriod(period, +it);
                console.log("qqqqq new", period, newPeriod);
                localItem[key] = newPeriod;
                props.onChange(key, localItem)
            }}>
                {it}
            </a>)
        })}


    </div>
}


export default Layout2
