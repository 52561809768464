import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import './Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";
import Select from "../../libs/Select";


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [contracts, setContracts] = useState([])

    let m = global.m;

    useEffect(() => {

        setContracts(m.from_to(0, 5).map(it => {
            return {name: 'Contr ' + it}
        }))
    }, [])
    return <div className={'row'}>
        <div className="col-xs-12">
            Period Selector . Pull за пред период
            <hr/>

            <div>Шапка:  ->>> команды</div>
            <div>Оборот: </div>
            <div>Агент: </div>
            <div>Дебиторка: </div>
            <hr/>
        </div>
        <div className="col-xs-12">

            {(contracts || []).map((it, ind) => {
                let size = 1;
                return (<div key={ind} className={'row contrItem'}>
                    <div className="col-xs-2">
                        {it.name} {it.currency || 'RUR'} {it.rate || 2000}
                    </div>
                    <div className={"col-xs-2"}>
                        <Select items={['paid', 'wait', 'invoice']}></Select>
                    </div> <div className={"col-xs-1"}>
                        <Select items={['team1', 'team2']}></Select>
                    </div><div className={"col-xs-" + size}>
                        <Input placeholder={'Plan'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'Fact'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'KorpNalogV'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'Account'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'CorpNalogAccount'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'Developer'} woLabel={true}></Input>
                    </div>
                    <div className={"col-xs-" + size}>
                        <Input placeholder={'ShadowDeveloper'} woLabel={true}></Input>
                    </div>
                </div>)
            })}
        </div>

    </div>
}

export default Layout2
