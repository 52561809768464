import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function Layout2(props) {
  console.log('*........ ## ROOT RENDER', props);

  return <div>404 Page
  </div>
}

export default Layout2
