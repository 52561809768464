import React, {useState, useEffect} from 'react';
import {
    gv,
    pubAccount,
    pubAccounts,
    pubContract,
    pubEmployee,
    pubemployee,
    pubemployees, pubPaidStatus, pubTeam,
    pubType,
    pubTypes
} from "./methods";
import {toRub} from "../methods/GenerateHistory";
import {ToRub} from "./TopStats";
import _ from 'underscore';
import {calcTransactions} from "./UserPeriodPreview";

function TransactionPreview(props) {
    let {ind, item, onChange, ignoreEdit} = props;
    let status = item.status || 'wait';

    let v = calcTransactions([item])
    // let total = 0, positive = 0, negative = 0;
    // _.each(item.items, (item, ind) => {
    //     let _total = toRub(item.amount, item.account, item.period) || 0;
    //     console.log("qqqqq item33333333333333", item, _total);
    //
    //     total += _total
    //     if (_total > 0) {
    //         positive += _total;
    //     } else {
    //         negative += total
    //     }
    //
    //
    // })
    let transaction = item;
    console.log("qqqqq ",);
    let fieldKey = {
        nalog_office: 'negative'
    }[item.type] || 'total'

    let isYear = props.isYear;
    let teams = {}
    let employees = {}
    return <div className={'row  transItem'} key={ind || 0} onClick={() => {
        props.onClick && props.onClick()
        if (ignoreEdit) {
            // alert('ignore Edit')
            return;
        }
        window.openMainModal({
            type: 'transaction', item, onChange
        })
    }}>
        {!props.detailedPreview && <>
            <div className="col-xs-2">
                <div className={'ellipse'}>
                    {item.isPeriodFavorite && <div className="fa fa-star pull-left"></div>}
                    <div className="ellipse">{item.name || pubType(item.subType || item.type)}</div>
                    {/*{item.subType && item.type === 'trati' && <div className={'ellipses'}><small>{pubType(item.subType)}</small></div>}*/}
                </div>
            </div>
            <div className="col-xs-2">
                <ToRub woPull={true}>{calcTransactions([item]).negative}</ToRub>
            </div>
            <div className="col-xs-8">
                <div className="row">
                    <div className="col-xs-2">
                        <StatusPub>{status}</StatusPub>
                    </div>
                    <div className="col-xs-10">
                        {(item.items || []).map((item, ind) => {
                            if (teams[item.team]) {
                                return null
                            }
                            teams[item.team] = true;
                            return <div className={'ib mr-5'}>{pubTeam(item.team)}</div>
                        })}
                        {(item.items || []).map((item, ind) => {
                            if (employees[item.employee]) {
                                return null
                            }
                            employees[item.employee] = true;
                            return <div className={'ib mr-5'}>{pubEmployee(item.employee)}</div>
                        })}
                    </div>
                </div>

            </div>
        </>}
        {props.detailedPreview && <>
            <div className="col-xs-2">
                <div className="ib paddTr">
                    <div className={'ellipse'}>
                        {item.isPeriodFavorite && <div className="fa fa-star"></div>}
                        <div className="ellipse">{item.name || pubType(item.subType || item.type)}</div>
                        {/*{item.subType && item.type === 'trati' && <div className={'ellipses'}><small>{pubType(item.subType)}</small></div>}*/}
                    </div>
                    <div style={{marginTop: '-10px'}}>
                        <StatusPub>{status}</StatusPub>
                    </div>
                    {/*<small>{pubPaidStatus(status)}</small>*/}
                </div>
                {/*<div className="ib paddTr">*/}
                {/*    <div>*/}
                {/*    </div>*/}

                {/*</div>*/}


            </div>
            {/*<div className="col-xs-2">*/}
            {/*    <div className="pull-right">*/}
            {/*        {isYear && <ToRub woPull={true} prefix={'('}*/}
            {/*                          postfix={')'}>{calcTransactions([item], null, 'amountMonthly')[fieldKey]}</ToRub>}*/}
            {/*        <ToRub woPull={true}>{v[fieldKey]}</ToRub>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="col-xs-10">
                {(item.items || []).map((item, ind) => {
                    return <RenderItemItem item={item} ind={ind} isYear={isYear}></RenderItemItem>
                })}
            </div>
            {/*<div className="col-xs-2">*/}

            {/*</div>*/}
            {/*<div className="col-xs-1">{pubType(item.type)}</div>*/}
            {/*<div className="col-xs-1">{pubEmployee(item.employee)}</div>*/}
            {/*<div className="col-xs-1">{pubTeam(item.teamFrom)}</div>*/}
            {/*<div className="col-xs-1">{pubAccount(item.accountFrom)}</div>*/}
            {/*<div className="col-xs-1">{pubContract(item.contract)}</div>*/}
        </>}
    </div>
}

export function StatusPub(props) {
    let status = props.children || 'wait';
    let name = pubPaidStatus(status);
    let cls = {
        reject: 'label-danger',
        wait: 'label-warning',
        paid: 'label-success'
    }
    return <small className={'label ellipse ' + (cls[props.isPayProblem ? 'reject' : status] || '')}
                  title={name + ', ' + status}>{name}</small>
}

export function RenderItemItem(props) {
    let {item} = props
    let sizes = props.sizes || [2, 2, 2, 2, 2, 2, 2]
    return <div key={props.ind || 0} className={'emptyWrapParent row'}>
        <div className={"col-xs-" + sizes[0]}>
            {props.isYear &&
                <ToRub prefix={' ('} postfix={') '}>{toRub(item.amountMonthly, item.account, item.period)}</ToRub>}
            <ToRub>{toRub(item.amount, item.account, item.period)}</ToRub>
        </div>
        <div className={"col-xs-" + sizes[1]}>
            <EmptyWrap>{pubType(item.type)}</EmptyWrap>
        </div>
        <div className={"col-xs-" + sizes[2]}>
            <EmptyWrap>{pubTeam(item.team)}</EmptyWrap>
        </div>
        <div className={"col-xs-" + sizes[3]}>
            {item.periodFrom || item.period} {item.periodTo != item.periodFrom && item.periodTo && <>- {item.periodTo}</>}
        </div>
        <div className={"col-xs-" + sizes[4]}>
            <EmptyWrap>{pubEmployee(item.employee)}</EmptyWrap>
        </div>
        <div className={"col-xs-" + sizes[5]}>
            <EmptyWrap>{pubAccount(item.account)}</EmptyWrap>
        </div>
        <div className={"col-xs-" + sizes[6]}>
            <EmptyWrap>{pubContract(item.contract)}</EmptyWrap>
        </div>
    </div>
}

export function EmptyWrap(props) {
    let v = props.children;
    if (v == '-') {
        return null;
    }
    return <div className={'ellipses'}>{v}</div>
}

export default TransactionPreview
