import React, {useRef, useState, useEffect} from 'react';
import MyModal from "../../libs/MyModal";
import Stringify from "../Stringify";
import TransactionEdit from "./TransactionEdit";


function Layout2(props) {
    let [isOpen, setIsOpen] = useState(true)
    let [originalItem, setOriginalItems] = useState(props.item)

    useEffect(() => {
        console.log("qqqqq isOpen", isOpen);
        if (!isOpen) {
            window.hideModal(props)
        }
    }, [isOpen])

    return <div>
        <MyModal isOpen={isOpen} onClose={() => setIsOpen(false)} size={'full'}>
            Транзакция {props?.modalId} #{props?.item?._id}
            <TransactionEdit {...props}></TransactionEdit>
        </MyModal>
    </div>
}

export default Layout2
