import React, {useRef, useState, useEffect} from 'react';
import {smartInd} from "./UserModal";
import _ from "underscore";
import Input from "../../libs/Input";
import Button from "../../libs/Button";
import Stringify from "../Stringify";
import {pubAccount, pubTeam, pubType} from "./methods";
import Smart from "../../libs/Smart";
import GroupedObjectRenderer, {
    addEmptyValuesIfNeed,
    createEmptyGroupChild,
    fromGroup,
    getOrCreate,
    getUniqueKeys, objMap,
    toGroup
} from "./GroupRender";
import MyAutoComplete from "./MyAutocomplete";

export function copy(v) {
    return JSON.parse(JSON.stringify(v))
}


function ContracsPeriodPreview(props) {
    let [isEdit, setIsEdit] = useState(props.isEdit)
    let [items, setItems] = useState([])
    let {period, contractId, onComplete, onChange} = props;

    useEffect(() => {
        // setItems(props.items)
        groupChilds(props.items)
    }, [])

    function groupChilds(items) {
        setItems(items.filter(it => it.type === 'prihod'))
    }

    let toggleEdit = (cb) => {
        setIsEdit(!isEdit)
        cb && cb();
    }

    let cancel = (scb) => {
        setIsEdit(false)
        console.log("qqqqq itemsitemsitemsitems", items);
        groupChilds(items)
        scb && scb()
    }
    let removeIt = (cb) => {
        remItem(items.map(it => it.type === 'prihod' ? it._id : null), cb)
    }

    let remItem = (_ids, cb) => {
        let _idsObj = _ids.reduce((acc, it) => ({...acc, [it]: true}), {})

        global.http.get('/multi-remove', {_ids: _ids.filter(it => it)}).then(r => {
            items =items.filter(item => !_idsObj[item._id] );
            setItems(items)
            onChange(items)
            cb && cb()

        })
    }
    let save = (scb) => {
        // let items = []
        // _.each(groupped, (itG, ind) => {
        //     _.each(itG, (arr, ind) => {
        //         items = items.concat(arr.map(copy))
        //     })
        //
        // })
        // setItems(items)
        // setIsEdit(false)
        scb && scb()
        onChange && onChange(items)
        global.http.post('/multi-save', {items, filter: {period, contract: contractId}}).then(r => {
            console.log("qqqqq multi save completeed", r);
            onChange && onChange(r.items)
        })
    }


    let removeRow = (scb) => {
        scb && scb();
    }


    let getDef = (it) => {
        let {accountFrom, amountFrom, accountTo, amountTo, teamFrom, teamTo, period} = it;
        return {accountFrom, amountFrom, accountTo, amountTo, teamFrom, teamTo}
    }


    let teams = (getUniqueKeys(items, 'teamFrom') || [])
    console.log("qqqqq original Items eams, grouppe", items);
    return (<div {...smartInd(props.ind)} className={'row'}>

        <div className={'headWrap'}>
            <div className="col-xs-2">Период</div>
            <div className="col-xs-10">
                <div className="row">
                    <div className="col-xs-2">Команда</div>
                    <div className="col-xs-5">
                        a
                    </div>
                    <div className="col-xs-5">
                        b
                    </div>
                </div>
            </div>
        </div>

        <div className="col-xs-2">
            {period}
            <div></div>
            <Button size={'xs'} color={4} onClick={(cb) => toggleEdit(cb)}>Edit</Button>
            {isEdit && <>
                <hr/>
                <Button onClick={removeIt}>Remove</Button>
                <Button onClick={save}>Save</Button>
                <Button onClick={cancel}>Cancel</Button>
                <hr/>
            </>}
        </div>
        <div className="col-xs-10">
            {(items || []).map((it, ind) => {
                it.special ??= it.special || {};
                it.special.dbItems ??= {};
                it.special.dbItems.korp_nalog ??= {type: 'korp_nalog', ...getDef(it), k: 1.23}
                let dbItems = it.special.dbItems;
                return (<div key={ind} className={'row'}>
                    {!isEdit && <>
                        <div className="col-xs-2">
                        {pubTeam(it.teamTo)}
                        </div>
                        <div className="col-xs-2">

                        {pubAccount(it.accountTo)}
                        </div>
                        <div className="col-xs-2">
                        {it.amountTo}
                        </div>
                        <div className="col-xs-2">
                        {dbItems.korp_nalog.k}
                        </div>
                        <div className="col-xs-2">
                        {pubAccount(dbItems.korp_nalog.accountTo)}
                        </div>
                        <div className="col-xs-2">
                        {dbItems.korp_nalog.amountTo}
                        </div>
                    </>}
                    {isEdit && <><div className="col-xs-1" onClick={() => {remItem([it._id])}}>
                        RemIT
                    </div>
                    <div className="col-xs-1">
                        <MyAutoComplete localItem={it} field={{key: 'teamTo', itemsKey: 'teams'}} onChange={v => {
                            items[ind].teamTo = v;
                            setItems([...items])
                            console.log("qqqqq vvvvvvvvvvv", v);
                        }}></MyAutoComplete>

                    </div>
                    <div className="col-xs-2">
                        <MyAutoComplete localItem={it} field={{key: 'accountTo', itemsKey: 'accounts'}} onChange={v => {
                            items[ind].accountTo = v;
                            setItems([...items])
                            console.log("qqqqq vvvvvvvvvvv", v);
                        }}></MyAutoComplete>

                    </div>
                    <div className="col-xs-2">
                        <Input value={it.amountTo} onChange={v => {
                            console.log("qqqqq on Changevvvv", v);
                            items[ind].amountTo = v;
                            setItems([...items])
                        }}/>
                    </div>
                    <div className="col-xs-2">
                        <Input value={dbItems.korp_nalog.k} onChange={v => {
                            dbItems.korp_nalog.k = v;
                            setItems([...items])
                            console.log("qqqqq on Changevvvv", v);
                        }}/>
                    </div>
                    <div className="col-xs-2">
                        <MyAutoComplete localItem={dbItems.korp_nalog} field={{key: 'accountTo', itemsKey: 'accounts'}} onChange={v => {
                            dbItems.korp_nalog.accountTo = v;
                            setItems([...items])
                            console.log("qqqqq vvvvvvvvvvv", v);
                        }}></MyAutoComplete>
                        {pubAccount(dbItems.korp_nalog.accountTo)}
                    </div>
                    <div className="col-xs-2">
                        <Input value={dbItems.korp_nalog.amountTo} onChange={v => {
                            dbItems.korp_nalog.amountTo = v;
                            setItems([...items])
                            console.log("qqqqq on Changevvvv", v);
                        }}/>
                    </div></>}

                </div>)
            })}
        </div>


    </div>)
}


export default ContracsPeriodPreview
