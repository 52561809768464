import React, {useState, useEffect} from 'react';
import {
    gv,
    pubTeam,
    pubAccount,
    pubAccounts,
    pubContract,
    pubemployee,
    pubemployees,
    pubType,
    pubTypes, pubPosition
} from "./methods";
import _ from 'underscore';
import Stringify from "../Stringify";
import {getStats, getStats2} from './methods'
import {ToRub} from "./TopStats";
import {calcTransactions} from "./UserPeriodPreview";
import {StatusPub} from "./TransactionPreview";

function TransactionUserPreview(props) {
    let {ind, items, employeeId, period, onChange} = props;


    let {total} = calcTransactions(items);

    // st.team = Object.keys(st.teams)

    console.log("qqqqq RERENDER ITTTTTTT", items);
    return <div className={'row transItem'}
                key={ind} onClick={() => {

    }}>

        <div className="col-xs-2 ">
            <div className="ellipse">
                {pubemployee(employeeId)}

            </div>
            {props.detailedPreview && <div style={{marginTop: '-5px'}}>
                {(items || []).map((item, ind) => {
                    return <small key={ind} className={'ib mr-5'}>
                        {pubPosition(item.position)}
                    </small>
                })}
            </div>}
        </div>
        <div className="col-xs-2">
            <ToRub woPull={true}>{total}</ToRub>
        </div>
        <div className="col-xs-8">
            {(items || []).map((item, ind) => {
                // let {total} = calcTransactions([item])
                let st = {}
                st = getStats2([item], (item) => {
                    console.log("qqqqq itemmmmmmmmmm", item.type);
                    let type = item.type;
                    let keys = [type, 'total'];
                    return keys
                }, {
                    mult: -1, woItems: true,
                })

                let team = item.teamFrom;
                let stTeam = st[team] || {}
                let total = stTeam?.total?.total || 0;
                let naRuki = (stTeam?.oklad?.total || 0) + (stTeam?.premia?.total || 0) + (stTeam?.year_premia?.total || 0)

                return (<div key={ind} className={'row hov'} onClick={() => {
                    window.openMainModal({
                        type: 'transaction', item: item
                    })
                }}>
                    {!props.detailedPreview && <>
                        <div className="col-xs-2">
                            <div>
                                <StatusPub>{item.status}</StatusPub>
                            </div>
                        </div> <div className="col-xs-3">
                            <div>
                                {pubTeam(item.teamFrom)}
                            </div>
                        </div>
                        <div className="col-xs-5">
                            {!props.detailedPreview && <div style={{}}>
                                {(items || []).map((item, ind) => {
                                    return <div key={ind} className={'ib mr-5'}>
                                        {pubPosition(item.position)}
                                    </div>
                                })}
                            </div>}
                        </div>
                    </>}
                    {props.detailedPreview && <>

                        <div className="col-xs-2">
                            {pubTeam(item.teamFrom)}
                            <div>
                                <StatusPub>{item.status}</StatusPub>
                            </div>
                        </div>
                        {/*<div className="col-xs-1">*/}
                        {/*    <div className="ellipse">*/}
                        {/*    {pubPosition(item.position)}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-xs-10">
                            <div>
                                <span className={'zpTitle'}>ЗП:</span><ToRub k={-1}
                                                                             woPull={true}>{naRuki}</ToRub> = <ToRub
                                k={-1}
                                woPull={true}>{stTeam?.oklad?.total || 0}</ToRub><ToRub k={-1}
                                                                                        woPull={true}>{stTeam?.premia?.total || 0}</ToRub>
                                {!!stTeam?.year_premia?.total && <ToRub k={-1}
                                                                        woPull={true}>{stTeam?.year_premia?.total || 0}</ToRub>}
                            </div>
                            <div>
                                <span className={'zpTitle'}>Налог:</span><ToRub k={-1}
                                                                                woPull={true}>{total - naRuki}</ToRub> = <ToRub
                            k={-1}
                            woPull={true}>{stTeam?.oklad_nalog?.total || 0}</ToRub><ToRub k={-1}
                                                                                          woPull={true}>{stTeam?.premia_nalog?.total || 0}</ToRub>
                            {!!stTeam?.year_premia?.total && <ToRub
                                k={-1}
                                woPull={true}>{stTeam?.year_premia_nalog?.total || 0}</ToRub>}
                        </div>
                    </div>
                    </>}
                </div>)
            })}


            {/*{(st.teams || []).map((team, ind) => {*/}
            {/*    let stTeam = st[team] || {}*/}
            {/*    // let total = stTeam?.total?.total || 0;*/}
            {/*    let naRuki = (stTeam?.oklad?.total || 0) + (stTeam?.premia?.total || 0) + (stTeam?.year_premia?.total || 0)*/}
            {/*    console.log("qqqqq stTeam", stTeam, items);*/}
            {/*    return (<div key={ind} className={'row'}>*/}
            {/*        <div className="col-xs-2">*/}
            {/*            <ToRub k={-1} woPull={true}>{total}</ToRub>*/}
            {/*        </div>*/}
            {/*        <div className="col-xs-2">*/}
            {/*            {pubTeam(team)}*/}
            {/*        </div>*/}
            {/*        <div className={'col-xs-8'}>*/}
            {/*            <div>*/}
            {/*                <span className={'zpTitle'}>ЗП:</span><ToRub k={-1} woPull={true}>{naRuki}</ToRub> = <ToRub*/}
            {/*                k={-1}*/}
            {/*                woPull={true}>{stTeam?.oklad?.total || 0}</ToRub><ToRub k={-1}*/}
            {/*                                                                        woPull={true}>{stTeam?.premia?.total || 0}</ToRub>*/}
            {/*                <ToRub k={-1}*/}
            {/*                       woPull={true}>{stTeam?.year_premia?.total || 0}</ToRub>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <span className={'zpTitle'}>Налог:</span><ToRub k={-1}*/}
            {/*                                                                woPull={true}>{total - naRuki}</ToRub> = <ToRub*/}
            {/*                k={-1}*/}
            {/*                woPull={true}>{stTeam?.oklad_nalog?.total || 0}</ToRub><ToRub k={-1}*/}
            {/*                                                                              woPull={true}>{stTeam?.premia_nalog?.total || 0}</ToRub><ToRub*/}
            {/*                k={-1}*/}
            {/*                woPull={true}>{stTeam?.year_premia_nalog?.total || 0}</ToRub>*/}


            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>)*/}
            {/*})}*/}

        </div>
    </div>
}


export default TransactionUserPreview
