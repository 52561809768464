import React, {useState} from 'react';
import axios from 'axios';
import Button from "../libs/Button";

function TelegramLoginForm({props}) {
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [info, setInfo] = useState({});
    const [isCode, setIsCode] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post('/api/login', {phoneNumber, password, code});
            console.log('Login success', response.data);
            // Handle successful login here (e.g., redirect or display a success message)
        } catch (error) {
            setError('Failed to log in. Please check your credentials and try again.');
        }
    };

    function getCodeRequest(cb) {
        setIsCode(true)
        cb && cb();
        global.http.get('/init-tg-login-flow', {phone: phoneNumber, userId: props?.item?._id}).then(r => {

        })
        // global.http.get('/api')
        // setTimeout(() => {
        //     cb && cb()
        //     setIsCode(true)
        // }, 500)
    }

    function verifyCode(cb) {
        cb && cb()
        global.http.get('/verify-tg-code', {phone: phoneNumber, code, userId: props?.item?._id}).then(r => {

        })
        // setIsCode(false)
        // setPhoneNumber()
    }

    function setPhoneNumber(v) {
        console.log("qqqqq propsssssss", props, v);
        let item = props.item
        item.tgPhoneNumber = v;
        window.onGlobalChange && window.onGlobalChange(item)
        // props.onChange && props.onChange(v, 'tgPhoneNumber')
    }

    function getInfo(cb) {
        global.http.get('/proxy/tg/me', {user: props?.item?._id}).then(r => {
            setInfo(r)
            cb && cb();
        })
    }


    let phoneNumber = props?.item?.tgPhoneNumber || ''


    return (<>

            <form onSubmit={handleSubmit}>
                {/*<label>*/}
                Телефон:
                <input type="text" id='telegramLoginNumber' value={phoneNumber}
                       onChange={e => setPhoneNumber(e.target.value)}/>
                {/*</label>*/}
                <Button onClick={(cb) => {
                    getCodeRequest(cb)
                }}>Получить код</Button>
                {isCode && <div>
                    {/*<label>*/}
                    Код:
                    <input type="text" value={code} onChange={e => setCode(e.target.value)}/>
                    {/*</label>*/}
                    <Button onClick={(cb) => {
                        verifyCode(cb)
                    }}>Подтвердить</Button>
                </div>}


            </form>
            <div>
                <hr/>

                <Button onClick={(cb) => {
                    getInfo(cb)
                }}>Получить инфо</Button>
                <pre style={{maxHeight: '200px', overflow: 'auto'}}>{JSON.stringify(info, null, 4)}</pre>

            </div>
        </>

    );
}

export default TelegramLoginForm;
