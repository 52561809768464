import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import {toGroup} from "./GroupRender";
import {pubType} from "./methods";
import Input from "../../libs/Input";
import TransaactionPreview from "./TransactionPreview";
import Button from "../../libs/Button";
import PeriodsSelector, {getFullPeriods} from "./PeriodsSelector";
import MyModal from "../../libs/MyModal";


function Layout2(props) {
    let {period, onChange} = props || {}
    let [isOpen, setIsOpen] = useState(0)
    let [pullPeriod, setPullPeriod] = useState('')
    let [ignoreObj, setIgnoreObj] = useState({})
    let [periodsByYear, setPeriods] = useState(_.groupBy(getFullPeriods(-3, 0), 'year'))
    let [items, setItems] = useState([])
    let [itemsObj, setItemsObj] = useState({})
    useEffect(() => {
            let ignoreArr = ['perevod'];

            let filter = {
                period: pullPeriod, $or: [{
                    type: {$nin: ignoreArr}
                }, {
                    type: {$in: ignoreArr}, isPeriodFavorite: true
                }]
            }
            filter.status = {$ne: 'archived'}

            pullPeriod && global.http.get('/transaction', {filter, per_page: 10000}).then(r => {
                let items = r.items;
                setItems(items)
                setItemsObj(toGroup(items, ['type']))
            })

        }
        ,
        [pullPeriod]
    )
    let pull = (scb) => {
        let cd = new Date().getTime();
        let transactions = items.map(it => {
            if (ignoreObj[it._id]) {
                return null;
            }
            let special = it.special || {};
            special.isYearPremia = false;
            // special.dbItems.yearPre
            return {
                ...it, _id: null, period,
                special,
                cd,
                date: cd, status: 'wait'
            }
        }).filter(it => it)


        global.http.post('/create-from-pull', {items: transactions}).then(r => {
            console.log("qqqqq create from upullllll",);
            let items = r.items;
            scb && scb();
            onChange && onChange(items)
            setIsOpen(false)
        })

    }

    return <div>
        <a onClick={() => setIsOpen(true)}>Пулл периода</a>
        <MyModal
            isOpen={isOpen}
            onClose={v => setIsOpen(false)}
        >
            {!pullPeriod && <>
                {(Object.keys(periodsByYear) || []).map((year, ind) => {
                    let arr = periodsByYear[year] || [];


                    return (<div key={ind} className={'row'}>
                        <div className="col-xs-2">
                            {year}
                        </div>
                        <div className="col-xs-10">
                            {(arr || []).map((it, ind) => {
                                let period = it.name;
                                return (<div key={ind} className={'ib'} style={{padding: '3px 5px'}} onClick={() => {
                                    setPullPeriod(period)
                                }}>
                                    {period}
                                </div>)
                            })}


                        </div>
                    </div>)
                })}

            </>}
            {pullPeriod && <>
                <div className="ib">
                    <Button onClick={(scb) => {
                        pull(scb)
                    }}>Скопировать выбранные</Button>
                    <Button color={4} onClick={(cb) => {
                        setIgnoreObj({})
                        cb && cb()
                    }}>
                        Выбрать все
                    </Button>
                    <Button color={4} onClick={(cb) => {
                        cb && cb()
                        let obj = {};
                        _.each(itemsObj, (arr, ind) => {
                            _.each(arr, (item, ind) => {
                                obj[item._id] = true

                            })
                        })
                        setIgnoreObj(obj)
                    }}>
                        Убрать все
                    </Button>
                </div>

                <div className="ib2s" style={{marginTop: '5px'}}>
                    <a onClick={(scb) => {
                        setPullPeriod(null);
                        scb && scb()
                    }}>Другой период (сейчас выбран {pullPeriod})</a>
                </div>
                <hr/>

                {(Object.keys(itemsObj) || []).map((key, ind) => {
                    let arr = itemsObj[key] || {}
                    return (<div key={ind} className={'row'}>
                        <div className="col-xs-2">
                            {pubType(key)}

                        </div>
                        <div className="col-xs-10">
                            {(arr || []).map((it, ind) => {
                                return (<div key={ind} className={'row'}>
                                    <div className="col-xs-1">
                                        <div className="ib">
                                            <Input type={'checkbox'} value={!ignoreObj[it._id]}
                                                   onChange={v => {
                                                       setIgnoreObj({...ignoreObj || {}, [it._id]: !v})
                                                   }}></Input>
                                        </div>
                                    </div>
                                    <div className="col-xs-11">
                                        <TransaactionPreview item={it} ignoreEdit={true} onClick={() => {
                                            setIgnoreObj({...ignoreObj || {}, [it._id]: !ignoreObj[it._id]})
                                        }}></TransaactionPreview>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>)
                })}
            </>}
        </MyModal>


        {/*Pull Periods*/}
        {/*<Stringify item={itemsObj}></Stringify>*/}
    </div>
}

export default Layout2
