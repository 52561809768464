import React, {useRef, useState, useEffect} from 'react';
import {smartInd} from "./UserModal";
import _ from "underscore";
import Input from "../../libs/Input";
import Button from "../../libs/Button";
import Stringify from "../Stringify";
import {pubAccount, generateHistory, pubTeam, pubType, pubEmployee, pubPaidStatus} from "./methods";
import Smart from "../../libs/Smart";
import GroupedObjectRenderer, {
    addEmptyValuesIfNeed,
    createEmptyGroupChild,
    fromGroup,
    getOrCreate,
    getUniqueKeys, objMap,
    toGroup
} from "./GroupRender";
import MyAutoComplete from "./MyAutocomplete";
import {toRub} from "../methods/GenerateHistory";
import {ToRub} from "./TopStats";
import {RenderItemItem} from "./TransactionPreview";
import {AmountEdit, NalogEdit} from "./Tr/OkladEdit";

export function copy(v) {
    return JSON.parse(JSON.stringify(v))
}

let onceSave;

function UserPeriodPerview(props) {
    let [isEdit, setIsEdit] = useState(props.isEdit)
    let [items, setItems] = useState([])
    let {period, employeeId, onComplete, onChange} = props;

    useEffect(() => {
        // groupChilds(props.items)
    }, [])

    useEffect(() => {
        groupChilds(copy(props.items))
    }, [JSON.stringify(props.items)])


    function groupChilds(items) {
        console.log('*........ ## group childsssssssssssssssss fitler', items);
        setItems(items.filter(it => it.type === 'oklad'))
    }

    let toggleEdit = (cb) => {
        setIsEdit(!isEdit)
        cb && cb();
    }

    let cancel = (scb) => {
        setIsEdit(false)
        groupChilds(copy(props.items || []))

        console.log("qqqqq itemsitemsitemsitems", items[0].amountFrom, props.items[0].amountFrom);
        scb && scb()
    }
    let removeIt = (cb) => {
        remItem(items.map(it => it.type === 'oklad' ? it._id : null), cb)
    }

    let addItem = (it) => {
        items.unshift({...it, _id: null})
        setItems([...items])
    }

    let remItem = (_ids, cb) => {
        let _idsObj = _ids.reduce((acc, it) => ({...acc, [it]: true}), {})
        items = items.filter(item => !_idsObj[item._id]);
        setItems([...items])
        // onChange(items)
        cb && cb();

        // global.http.get('/multi-remove', {_ids: _ids.filter(it => it)}).then(r => {
        //     items =items.filter(item => !_idsObj[item._id] );
        //     setItems(items)
        //     onChange(items)
        //     cb && cb()
        //
        // })
    }
    // let save = (scb, opts) => {
    //     // let items = []
    //     // _.each(groupped, (itG, ind) => {
    //     //     _.each(itG, (arr, ind) => {
    //     //         items = items.concat(arr.map(copy))
    //     //     })
    //     // })
    //     // setItems(items)
    //     // setIsEdit(false)
    //
    //     // onChange(items)
    //
    //     // scb && scb();
    //     //
    //     //
    //     // onChange && onChange(items);
    //     global.http.post('/multi-save', {
    //         items: generateHistory(items),
    //         filter: {period, employee: employeeId}
    //     }).then(r => {
    //         console.log("qqqqq multi save completeed", r);
    //         onChange && onChange(r.items)
    //         scb && scb();
    //         !opts?.woClose && setIsEdit(false)
    //     })
    // }


    let removeRow = (scb) => {
        scb && scb();
    }


    let getDef = (it) => {
        let {accountFrom, amountFrom, accountTo, amountTo, teamFrom, teamTo, period} = it;
        return {accountFrom, amountFrom, accountTo, amountTo, teamFrom, teamTo}
    }


    useEffect(() => {
        !onceSave && props.saveBefore && setTimeout(() => {
            onceSave = true;
            window?.$(`[data-btn-save]`).click()
        }, 500)
    }, [])

    let teams = (getUniqueKeys(items, 'teamFrom') || [])
    console.log("qqqqq original Items eams, grouppe USERS", items);
    return (<div {...smartInd(props.ind)} className={'row'}>

        <div className={'headWrap'}>
            <div className="col-xs-2"></div>
            <div className="col-xs-10">
                <div className="row">
                    <div className="col-xs-2"></div>
                    <div className="col-xs-5">
                    </div>
                    <div className="col-xs-5">
                    </div>
                </div>
            </div>
        </div>

        <div className="col-xs-1">
            {period}
        </div>
        <div className="col-xs-1">
            {pubEmployee(employeeId)}
        </div>

        <div className="col-xs-10 ">
            {(items || []).map((it, ind) => {
                it.special ??= it.special || {};
                it.special.dbItems ??= {};
                it.special.dbItems.oklad_nalog ??= {type: 'oklad_nalog', ...getDef(it)}
                // let dbItems = it.special.dbItems;
                let {total, positive, negative} = calcTransactions([it])
                return (<div key={ind} className={'row hov'} onClick={() => {
                    window.openMainModal({
                        type: 'transaction',
                        item: it,
                        // onChange: (v) => {
                        //     console.log("qqqqq vvvvvvvvvvvvvvv", v, onChange);
                        // },
                    })
                }}>
                    {/*{!isEdit && <>*/}
                    <div className="col-xs-2">
                        <div className="ellipses">
                            {pubPaidStatus(it.status)}
                        </div>
                    </div>
                    <div className="col-xs-1">
                        <ToRub>{total}</ToRub>
                    </div>
                    <div className="col-xs-9">
                        {(it.items || []).map((it, ind) => {
                            return <RenderItemItem item={it} ind={ind}
                                                   sizes={[2, 2, 2, 3, 0, 2, 0]}
                            ></RenderItemItem>
                        })}
                    </div>
                    {/*</>}*/}
                    {/*{isEdit && <>*/}
                    {/*    <div className="col-xs-1">*/}
                    {/*        <div style={{cursor: 'pointer'}} onClick={() => {*/}
                    {/*            remItem([it?._id])*/}
                    {/*        }}>ReMITem #{it._id}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-2">*/}
                    {/*        <MyAutoComplete localItem={it} field={{key: 'teamFrom', itemsKey: 'teams'}} onChange={v => {*/}
                    {/*            items[ind].teamFrom = v;*/}
                    {/*            setItems([...items])*/}
                    {/*            console.log("qqqqq vvvvvvvvvvv", v);*/}
                    {/*        }}></MyAutoComplete>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-2">*/}
                    {/*        <MyAutoComplete localItem={it} field={{key: 'accountFrom', itemsKey: 'accounts'}}*/}
                    {/*                        onChange={v => {*/}
                    {/*                            items[ind].accountFrom = v;*/}
                    {/*                            setItems([...items])*/}
                    {/*                            console.log("qqqqq vvvvvvvvvvv", v);*/}
                    {/*                        }}></MyAutoComplete>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-2">*/}
                    {/*        <MyAutoComplete localItem={it} field={{key: 'position', itemsKey: 'positions'}}*/}
                    {/*                        onChange={v => {*/}
                    {/*                            items[ind].position = v;*/}
                    {/*                            setItems([...items])*/}
                    {/*                            console.log("qqqqq vvvvvvvvvvv", v);*/}
                    {/*                        }}></MyAutoComplete>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-1">*/}
                    {/*        <Input value={it.amountFrom} onChange={v => {*/}
                    {/*            console.log("qqqqq on Changevvvv", v);*/}
                    {/*            items[ind].amountFrom = v;*/}
                    {/*            setItems([...items])*/}
                    {/*        }}/>*/}
                    {/*        /!*</div>*!/*/}
                    {/*        /!*<div className="col-xs-2">*!/*/}
                    {/*        <Input value={dbItems['oklad_nalog'].amountFrom} onChange={v => {*/}
                    {/*            dbItems['oklad_nalog'].amountFrom = v;*/}
                    {/*            setItems([...items])*/}
                    {/*            console.log("qqqqq on Changevvvv", v);*/}
                    {/*        }}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-4">*/}
                    {/*        <PremiaEdit field={'premia'} dbItems={dbItems} it={it}*/}
                    {/*                    onChange={() => setItems([...items])}></PremiaEdit>*/}

                    {/*        {it.special.isYearPremia && <PremiaEdit field={'year_premia'} dbItems={dbItems} it={it}*/}
                    {/*                                                onChange={() => setItems([...items])}></PremiaEdit>}*/}
                    {/*    </div>*/}
                    {/*    <div className="col-xs-2">*/}

                    {/*    </div>*/}
                    {/*</>}*/}

                </div>)
            })}
        </div>

    </div>)
}

export let PremiaEdit = (props) => {
    let {it, items, field, dbItems, onChange} = props;
    let nalogField = field + '_nalog'
    dbItems[field] = dbItems[field] || {}
    dbItems[nalogField] = dbItems[nalogField] || {}


    let size = field == 'premia' ? 4 : 4
    return <>
        <div className="col-xs-4">
            <MyAutoComplete localItem={dbItems[field]}
                            label={'Аккаунт'}
                            field={{key: 'accountFrom', itemsKey: 'accounts'}}
                            onChange={v => {
                                dbItems[field].accountFrom = v;
                                onChange()
                                console.log("qqqqq vvvvvvvvvvv", v);
                            }}></MyAutoComplete>
        </div>
        <div className={"col-xs-" + size}>
            {/*</div>*/}
            {/*<div className="col-xs-3">*/}
            <AmountEdit
            label={'Сумма премии'}
            accountId={(dbItems[field] || {}).accountFrom}

                value={dbItems[field].amountFrom} onChange={v => {
                dbItems[field].amountFrom = v;
                onChange(dbItems)
                console.log("qqqqq on Changevvvv", v);
            }}/>
        </div>
        <div className={"col-xs-" + size}>

            <NalogEdit
                label={'Налог на премию'}
                value={dbItems[field].nalog_k} onChange={v => {
                dbItems[field].nalog_k = v;
                onChange(dbItems)
                console.log("qqqqq on Changevvvv", v);
            }}/>

        </div>


        {/*{field != 'premia' && <div className={"col-xs-" + size}><Input*/}
        {/*    placeholder={'PeriodToPremia'}*/}
        {/*    value={it?.special?.yearPremiaPeriodTo} onChange={v => {*/}
        {/*    it.special.yearPremiaPeriodTo = v;*/}
        {/*    onChange(dbItems)*/}
        {/*}}/></div>}*/}

    </>
}

export let calcTransactions = (items, filterFn, key = 'amount') => {
    let total = 0, positive = 0, negative = 0;

    _.each(items, (tr, ind) => {
        _.each(tr.items, (item, ind) => {
            if (filterFn && !filterFn(item, tr)) {
                return;
            }
            let amount = toRub(item[key || 'amount'] || 0, item.account, item.period)
            total += amount;
            positive += (amount > 0 ? amount : 0)
            negative += (amount < 0 ? amount : 0)

        })

    })

    return {total, positive, negative}

}
export default UserPeriodPerview
