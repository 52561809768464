import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import './Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";
import PeriodsSelector, {getDefPeriod} from "./PeriodsSelector";
import ContractModal from "./ContractModal";
import TransaactionPreview from './TransactionPreview'
import TransactionEdit from "./TransactionEdit";
import Button from "../../libs/Button";
import TransactionUserPreview from './TransactionUserPreview';
import TransactionContractPreview from "./TransactionContractPreview";
import UserPeriodPreview, {calcTransactions} from './UserPeriodPreview';
import ContractsPeriodPerview from './ContractsPeriodPerview'
import {pubEmployee, pubTeam, getStats2, pubAccount, pubType, pubContract, setHash, getHash} from "./methods";
import TopStats, {ToRub} from "./TopStats";
import MyAutoComplete from "./MyAutocomplete";
import OfficeUsers from "./OfficeUsers";
import PullPeriods from "./PullPeriods";
import StatsPeriodShort from "./StatsPeriodShort";
import {toRub} from "../methods/GenerateHistory";
import {isGoodRole} from "../../App";
import MyModal from "../../libs/MyModal";
import {getter} from "../methods/Fetcher";

let trHttp = getter('/transaction')

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [contractIds, setContractIds] = useState([])
    let [hidden, setHidden] = useState({nalog_office: true})
    let [isDebug, setIsDebug] = useState(true)
    let [filter, setFilter] = useState(getHash({period: getDefPeriod({delta: -1}), teams: []}))
    let [userIds, setUserIds] = useState([])
    let [transactionsByUsers, setTransactionsByUsers] = useState({})
    let [transactionsByContracts, setTransactionsByContracts] = useState({})
    let [transactions, setTransactions] = useState([])
    let [transactionsY, setTransactionsY] = useState([])
    let [statPeriod, setStatPeriod] = useState([])
    let [contracts, setContracts] = useState([])

    let m = global.m;

    let finRole = global?.user?.get_info()?.customData?.finRole;


    let {period, teams = {}, monthlyType} = filter;
    console.log("qqqqq FILTERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR", filter?.period, getHash()?.period, {teams, period});

    useEffect(() => {
        window?.start()?.then()
    }, [])
    useEffect(() => {

        setHash({period, teams, monthlyType})

        let filter = {status: {$ne: 'archived'}, period};
        if (teams && teams.length) {
            filter.teams = {$in: teams}
        }


        Promise.all([
            global.http.get('/my-transaction', {filter, per_page: 10000}),
            global.http.get('/get-period-stats', {period}),
            global.http.get('/get-yearly-transactions', {filter, period})
        ]).then(v => {
            console.log("qqqqq vvvvvvv", v);
            window.periodStats = v[1].item
            setStatPeriod(window.periodStats)
            handleSetTransactions(v[0].items)
            setTransactionsY(v[2].items)
        })


        // setContracts(m.from_to(0, 5).map(it => {
        //     return {name: 'Contr ' + it}
        // }))
    }, [JSON.stringify({period, teams, monthlyType})])

    function handleSetTransactions(items, params) {
        !params?.woSet && setTransactions(items)
        let transactionsByContacts = {}
        let transactionsByUsers = {}
        console.log('*........ ## handle save transctions', items, params);
        _.each(items, (item, ind) => {

            if (item.employee && item.type === 'oklad') {
                transactionsByUsers[item.employee] ??= [];
                transactionsByUsers[item.employee].push(item)
            }
            if (item.contract && item.type === 'prihod') {
                transactionsByContacts[item.contract] ??= []
                transactionsByContacts[item.contract].push(item)
            }
        })

        setTransactionsByUsers(transactionsByUsers)
        setTransactionsByContracts(transactionsByContacts)

        console.log("qqqqq foundddddddddddddddddddddddddddd2222222", transactionsByContacts);

        setContractIds(_.sortBy(Object.keys(transactionsByContacts), it => {
            let v = calcTransactions(transactionsByContacts[it])
            return (-1) * v.total;
        }))
        setUserIds(_.sortBy(Object.keys(transactionsByUsers), it => {
            let v = calcTransactions(transactionsByUsers[it])
            console.log("qqqqq vvvvvvvvvvvvvvvvvvvvvvvvvv", v);
            return (1) * v.total;
        }))
    }

    let updateTransaction = (item) => {
        updateTransactionY(item)
        setTransactions(transactions => {

            console.log("qqqqq item!!!!!!!!!!!!!!!!!", item.status);
            if (item.status == 'archived') {
                let items = transactions.filter(it => it._id != item._id);

                handleSetTransactions(items, {woSet: true})
                return items
            }

            let isFound;
            _.each(transactions, (it, ind) => {
                if (it._id == item._id) {
                    isFound = true;
                    transactions[ind] = {...it, ...item}
                }
            })

            if (!isFound) {
                transactions = [item, ...transactions]
            }
            console.log('*........ ## update transaction', item, transactions);
            handleSetTransactions(transactions, {woSet: true})
            return [...transactions]
        })
    }

    let updateTransactionY = (item) => {
        setTransactionsY(transactions => {
            _.each(transactions, (it, ind) => {
                if (it._id == item._id) {
                    transactions[ind] = {...it, ...item}
                }
            })
            return [...transactions].filter(it => it.status != 'archived')
        })
    }


    let isType = (v, type) => v?.type == type;

    let employeeId = (userIds || [])[0]
    let contractId = (contractIds || [])[0]

    let syncTransactions = (oldItems, newItems) => {
        let obj1 = _.groupBy(oldItems, '_id')
        let obj2 = _.groupBy(newItems, '_id')
        let arr = []
        let arrObj = {}
        transactions.forEach(it => {
            arrObj[it._id] = true;
            let it1 = (obj1[it._id] || {})[0]
            let it2 = (obj2[it._id] || {})[0]
            if (!it1 && !it2) {
                arr.push(it)
            }

            if (it1 && !it2) {
                return;
            }

            it2 && arr.push(it2)
        })


        _.each(newItems, (it, ind) => {
            if (!arrObj[it._id]) {
                arr.push(it)
            }

        })

        handleSetTransactions(arr)

    }


    // let filter = {period, teams}

    // useEffect(() => {
    // }, [JSON.stringify({period, teams})])
    // console.log("qqqqq filter", {period, teams});

    let setPeriod = (period) => {
        console.log("qqqqq SET PERIODDDDDDDDDDDDDDDDDDDDDDD", period);
        setFilter({...filter, period})
    }
    let setTeams = (teams) => {
        setFilter({...filter, teams})
    }

    let onChangeBtn = (v) => {
        handleSetTransactions([v, ...transactions])
    }
    window.updateTransaction = updateTransaction;
    window.curPeriod = period;
    window.curTeams = teams;

    let isOffice = it => it.type === 'nalog_office';
    let isRaspredi = it => it.type === 'raspredi';
    let isPerevod = it => it.type === 'perevod';
    let isTrati = it => it.type === 'trati';
    let anyOthers = it => {
        return !isOffice(it) && !isRaspredi(it) && !isPerevod(it) && !isTrati(it)
    }

    let groups = [{
        name: 'Распред',
        type: 'raspredi',
        def: {},
        filterFn: isRaspredi,
    }, {
        name: 'Перевод',
        type: 'perevod',
        def: {},
        filterFn: isPerevod,
    }, {
        name: 'Разные траты',
        type: 'trati',
        def: {
            status: 'paid'
        },
        totalKey: 'negative',
        filterFn: isTrati,
    }, {
        name: 'Корп налог',
        type: 'nalog_office',
        woAdd: true,
        def: {},
        totalKey: 'negative',
        filterFn: isOffice,
    },]


    let {isBig} = filter;
    console.log("qqqqq aaaaaaaaaaaad", );
    return <div className={'row ' + (filter.detailedPreview ? 'detailedPreview' : 'shortPreview')}>


        {/*{transactions && transactions[0] && <div className="col-xs-12 itemEdit">*/}
        {/*    <TransactionEdit item={transactions[0]} onChange={updateTransaction}></TransactionEdit>*/}
        {/*</div>}*/}

        <div className="col-xs-2">
            <PeriodsSelector
                value={period}
                onChange={v => {
                    setPeriod(v)
                }}></PeriodsSelector>
        </div>
        <div className="col-xs-8">
            <div style={{marginTop: '-5px'}}>

                <MyAutoComplete
                    label={'Команды'}
                    localItem={{teams: teams || []}}
                    field={{isMulti: true, key: 'teams', itemsKey: 'teams'}} onChange={v => {
                    setTeams(v)
                    console.log("qqqqq vvvvvvvvvvv TEAMS", v);
                }}></MyAutoComplete>
            </div>

        </div>
        <div className="col-xs-2">
            <div className="pull-right tr" style={{fontSize: '11px'}}>
                <StatsPeriodShort statPeriod={statPeriod} transactions={transactions}
                                  onChangeStats={(v) => {
                                      console.log("qqqqq vvvvvvvvvvvvvvvvonChangeStats", v);
                                      setStatPeriod(v)
                                  }}
                ></StatsPeriodShort>
                <div>
                    <PullPeriods period={period} pullPeriod={period}
                                 onChange={(items) => {
                                     handleSetTransactions([...items, ...transactions])
                                 }}
                    ></PullPeriods>
                </div>
                <div>
                    <OfficeUsers transactions={transactions} teams={teams} onChange={updateTransaction}
                                 period={period}></OfficeUsers>
                </div>
                <div className="pull-right">
                    <Button size={'xs'} onClick={(cb) => {
                        cb && cb()
                        setFilter({...filter, isBig: !isBig})
                    }}>Детальные данные</Button>
                </div>


            </div>
        </div>

        {isGoodRole('stats') && <div className="col-xs-12">


            <TopStats teams={teams} period={period}
                      transactionsY={transactionsY}
                      onChangeFilter={(f) => {
                          setFilter({...filter, ...f})
                      }}
                      filter={filter}
                      transactions={transactions}></TopStats>
        </div>}

        <div className="col-xs-12">
            <Input type={'checkbox'} label="Детали транзакций" value={filter?.detailedPreview} onChange={v => {
                setFilter({...filter || {}, detailedPreview: v})
            }}></Input>
        </div>
        {<div className="col-xs-12 wrapTrBlock first">
            <AddBtn name={`Контракт [ x${contractIds?.length} ]`} type={'prihod'} onChange={onChangeBtn}></AddBtn>
            <AmountAndPay items={contractIds.reduce((acc, contractId) => {
                return [...acc, ...transactionsByContracts[contractId]]
            }, [])}
                          groupFn={(item) => {
                              console.log('55111', item)
                              return item?.special?.isAgent ? 'agent' : 'ooo';//true
                          }}
                          updateTransaction={updateTransaction}
                          woEdit={true}
            ></AmountAndPay>
            {(contractIds || []).map((contractId, ind) => {
                return (<div key={ind} className={''} onClick={() => {
                }}>
                    <TransactionContractPreview items={transactionsByContracts[contractId]} contractId={contractId}
                                                detailedPreview={filter?.detailedPreview}
                                                onChange={v => {
                                                    console.log("qqqqq vvvvvvvvvvvvv", v);
                                                }}></TransactionContractPreview>
                </div>)
            })}
        </div>}
        {isGoodRole('zp') && <div className="col-xs-12 wrapTrBlock">
            <AddBtn name={`ЗП [ x${userIds?.length} ]`} type={'oklad'} onChange={onChangeBtn}></AddBtn>
            <AmountAndPay items={userIds.reduce((acc, employeeId) => {
                return [...acc, ...transactionsByUsers[employeeId]]
            }, [])}
                          updateTransaction={updateTransaction}

            ></AmountAndPay>

            {(userIds || []).map((employeeId, ind) => {
                return (<div key={ind} className={''}>
                    <TransactionUserPreview
                        period={period}
                        detailedPreview={filter?.detailedPreview}
                        items={transactionsByUsers[employeeId]}
                        employeeId={employeeId}
                        onChange={items => {
                            transactionsByUsers[employeeId] = items
                            setTransactionsByUsers({...transactionsByUsers})
                            console.log("qqqqq vvvvvvvvvvvvv itemsitemsitemsitemsitemsitems", items);
                        }}></TransactionUserPreview>
                </div>)
            })}
        </div>}

        {(groups || []).map((it, ind) => {
            let {name, type, def, filterFn} = it;
            let allFn = () => true;
            filterFn = filterFn || allFn
            let filteredItems = _.sortBy((transactions || []).filter(filterFn), it => {
                let v = calcTransactions([it]);
                console.log("qqqqq aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", v.negative);
                    return v.negative + ((it.isPeriodFavorite ? -1000000000000 : 0))
                }
            );
            console.log("qqqqq aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa11111111111111111111", filteredItems);

            let totalV = calcTransactions(filteredItems);
            let isHidden = hidden[type]

            if (!isGoodRole(type)) {
                return;
            }
            return (<div key={ind} className={'col-xs-12 wrapTrBlock'}>
                <AmountAndPay items={filteredItems} updateTransaction={updateTransaction}
                              woEdit={ind == 'nalog_office'}></AmountAndPay>
                <div className={'ib'} style={{minWidth: '120px', marginRight: '10px'}}>

                    <Button size={'xs'} color={4} onClick={(cb) => {
                        setHidden({...hidden || {}, [type]: !hidden[type]})
                        cb && cb()
                    }}>
                        <div className=" o5 fa fa-caret-down"></div>
                    </Button>
                    {it.woAdd && <>
                        <small style={{marginLeft: '10px'}}>{name}
                            <small>( {filteredItems.length || 0} )</small></small>
                    </>}

                    {!it.woAdd &&

                        <AddBtn name={<span>{name} <small>( {filteredItems.length || 0} )</small></span>} type={type}
                                onChange={onChangeBtn} def={def}></AddBtn>}
                </div>
                {!isHidden && <div>
                    {(filteredItems || []).map((it, ind) => {
                        return (
                            <TransaactionPreview ind={ind} item={it}
                                                 detailedPreview={filter?.detailedPreview}
                                                 isYear={it.periodFrom != it.periodTo}
                                                 onChange={updateTransaction}></TransaactionPreview>)
                    })}</div>}

            </div>)
        })}


        {/*<div className="col-xs-12 wrapTrBlock">*/}
        {/*    <AddBtn name={'Трата'} type={'trati'} onChange={onChangeBtn}></AddBtn>*/}
        {/*    <div></div>*/}
        {/*    /!*{(transactions || []).filter(it => !it.isPeriodFavorite && !isType(it, 'nalog_office')).map((it, ind) => {*!/*/}
        {/*    {(transactions || []).map((it, ind) => {*/}
        {/*        return (<TransaactionPreview ind={ind} item={it} onChange={updateTransaction}></TransaactionPreview>)*/}
        {/*    })}*/}
        {/*</div>*/}

        {/*<div className="col-xs-12 wrapTrBlock">*/}
        {/*    <AddBtn name={'Распред'} type={'raspredi'} onChange={onChangeBtn}></AddBtn>*/}
        {/*    {(transactions || []).filter(isRaspredi).map((it, ind) => {*/}
        {/*        return (<TransaactionPreview ind={ind} item={it} onChange={updateTransaction}></TransaactionPreview>)*/}
        {/*    })}*/}
        {/*</div>*/}


        {isGoodRole('year') && <div className="col-xs-12 wrapTrBlock">
            Годовые транзакции
            {(transactionsY || []).map((it, ind) => {
                return (<TransaactionPreview ind={ind} item={it}
                                             isYear={true}
                                             onChange={updateTransactionY}></TransaactionPreview>)
            })}
        </div>}


    </div>
}

export function AmountAndPay(props) {
    let [isOpen, setIsOpen] = useState(null)
    let [ignoreObj, setIgnoreObj] = useState({})
    let {items, onChange, groupFn, updateTransaction} = props;
    let total = 0;
    let totalGroups = {};
    let contracts = {}

    console.log("qqqqq items22222222222", items, props);
    _.each(items, it => {
        let groupKey = groupFn ? groupFn(it) : '';
        // if (groupFn) {
        //     let key = groupFn(it)
        // }
        _.each(it.items, it => {
            let value = toRub(/prihod/gi.test(it.type) ? it.amount : it.amount < 0 ? it.amount : 0, it.account, it.period);
            total += value
            if (groupKey) {
                totalGroups[groupKey] ??= {value: 0, count: 0}
                totalGroups[groupKey].value += value;
            }
        })
        if (groupKey && !contracts[it.contract]) {
            totalGroups[groupKey].count++
            contracts[it.contract] = true;
        }

    })

    let setStatus = (cb, status) => {
        cb && cb()
        setIsOpen(null)
        onChange(status, items.filter(it => {
            let isOk = !ignoreObj[it._id] && it.status != status
            if (isOk) {
                it.status = status;
                trHttp.update(it)
                updateTransaction(it)
            }
            return isOk
        }))
    }

    let totalGroupKeys = Object.keys(totalGroups)
    return <div className={'pull-right'}>
        <MyModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <Button onClick={(cb) => {
                setStatus(cb, 'paid')
            }}>Произвести оплату</Button>
            <Button onClick={(cb) => {
                setStatus(cb, 'wait')
            }}>Отменить оплату</Button>
            {(items || []).map((it, ind) => {
                return (<div key={ind} className={'row'}>
                    <div className="col-xs-1">
                        <Input type="checkbox" value={!ignoreObj[it._id]} onChange={() => {
                            setIgnoreObj({...ignoreObj, [it._id]: !ignoreObj[it._id]})
                        }}></Input>
                    </div>
                    <div className="col-xs-10">
                        <TransaactionPreview ind={ind} item={it}
                                             isYear={true}
                                             onChange={updateTransaction}></TransaactionPreview>
                    </div>

                </div>)
            })}

        </MyModal>
        <div className={'ib ml-5 payHover'} style={{marginLeft: '10px'}}>
            {!props.woEdit && !!items?.length &&
                <small style={{marginRight: '10px'}}><a onClick={() => setIsOpen(true)}>Оплата/Отмена
                    (х{items.length})</a></small>}
            {(totalGroupKeys || []).map((key, ind) => {
                return (<div key={ind} className={'ib mr10'}>
                    {key} [x{totalGroups[key]?.count}]: <ToRub woPull={false}>{totalGroups[key]?.value}</ToRub>
                </div>)
            })}

            <ToRub woPull={false}>{total}</ToRub>
        </div>

    </div>
}

let getDefTr = () => {
    let period = getDefPeriod();
    let team = (window?.curTeams || {})[0] || (window?.info?.teams || {})[0]?._id
    console.log("qqqqq period2222", period, team, window?.curTeams);
    return {
        teamFrom: team,
        teamTo: team,
        team,
        date: new Date().getTime(),
        period,
        periodFrom: period,
        periodTo: period,
        status: 'wait'
    }
}


export function AddBtn(props) {
    return <Button
        color={4}
        size={'xs'}
        onClick={(scb) => {
            scb && scb()
            window?.openMainModal({
                type: 'transaction',
                isNew: props.isNew,
                item: {...getDefTr(), type: props.type, ...props.def || {}},
                onChange: props.onChange
            })
        }
        }>+ {props.name}</Button>

}


export default Layout2
