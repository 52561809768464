export const keysMapFn = (params) => {
    let {monthlyType} = params
    return (item, opts, tr) => {
        let {type, status, amount, periodsCount} = item || {};
        if (opts?.amountKey == 'amount' && /onlyy|withy/gi.test(monthlyType) && periodsCount > 1) {
            return []
        }
        let parentTeamFn = (item, tr) => {
            return tr.teamTo;
        }

        let trType = (tr || {}).type;
        let isOfficePlus = trType == 'nalog_office' && amount > 0;
        let isPerevod = /perevod/gi.test(trType);

        let keys = isOfficePlus ? ['cacl_only.office_plus'] : [type, 'calc_only.total'];
        if (status !== 'paid' && /prihod/gi.test(trType)) {
            keys.push({key: 'calc_only.debitor', teamFn: parentTeamFn})
        }

        if (trType !== 'raspredi') {
            keys.push('calc_only.total_wo_raspredi')
        }

        if (isPerevod) {
            keys.push('calc_only.perevod')
        }

        if (!isPerevod && amount < 0 && !isOfficePlus && trType !== 'prihod' && trType != 'raspredi') {
            keys.push('calc_only.trati')

            if (periodsCount > 1) {
                keys.push('calc_only.ytrati')
            } else {
                keys.push('calc_only.mtrati')
            }
        }
        // if (trType == 'prihod') {
        //     keys.push('calc_only.prihod_nalog')
        // }

        if (trType === 'prihod') {
            keys.push({
                key: 'calc_only.prihod_and_korp', teamFn: parentTeamFn
            })
            keys.push('calc_only.prihod_wo_korp')
        }

        return keys
    }
}


