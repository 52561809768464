import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function Layout2(props) {
  let localItem = props?.props?.localItem || props?.localItem || props?.props?.item || props?.item || {};
  console.log('*........ ## ROOT RENDER', props);

  return <pre>
    {JSON.stringify(localItem, null, 1)}
  </pre>
}

export default Layout2
