import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import MyAutoComplete from "../MyAutocomplete";
import Input from "../../../libs/Input";
import {PremiaEdit} from "../UserPeriodPreview";
import Button from "../../../libs/Button";
import {ToRub} from "../TopStats";


function OkladEdit(props) {

    let {item, onChange} = props;
    item.special ??= {}
    item.special.dbItems ??= {}
    let special = item.special;
    let {dbItems} = item.special;
    dbItems.oklad_nalog ??= {}

    return <>
        <div className="col-xs-12">
        </div>

        <div className="col-xs-12">
            <div className={'perevod-title'}>Списание</div>
            <Smart
                obj={item}
                defSize={4}
                items={[

                    {
                        path: 'Main/MyAutocomplete',
                        key: 'teamFrom',
                        itemsKey: 'allTeams',
                        label: 'Команда',
                    },{
                        path: 'Main/MyAutocomplete',
                        key: 'accountFrom',
                        itemsKey: 'accounts',
                        label: 'Аккаунт',
                    }, {
                        name: 'Сумма', key: 'amountFrom', type: 'amount', accountKey: 'accountFrom'
                    }
                    ]
                }
                onChange={onChange}
            >
            </Smart>
        </div>
        <div className="col-xs-12">
            <div className={'perevod-title'}>Зачисление</div>
            <Smart
                obj={item}
                defSize={4}
                items={[
                  {
                        path: 'Main/MyAutocomplete',
                        key: 'teamTo',
                        itemsKey: 'allTeams',
                        label: 'Команда',
                    },{
                        path: 'Main/MyAutocomplete',
                        key: 'accountTo',
                        itemsKey: 'accounts',
                        label: 'Аккаунт',
                    }, {
                        name: 'Сумма', key: 'amountTo', type: 'amount', k: 1, accountKey: 'accountTo'
                    }
                    ]
                }
                onChange={onChange}
            >
            </Smart>
        </div>
        <div className="col-xs-12">
            {props?.isSmart && <>
                <div className={'perevod-title'}>Виртуал аккаунт смарт</div>
                <Smart
                    obj={item}
                    defSize={4}
                    items={[
                       {
                            path: 'Main/MyAutocomplete',
                            key: 'special.virtualTeam1',
                            itemsKey: 'virtualTeams',
                            // label: 'Виртуал Аккаунт (Смарт)',
                        }, {
                            path: 'Main/MyAutocomplete',
                            key: 'special.virtualAccount1',
                            itemsKey: 'accounts',
                            // label: 'Виртуал Аккаунт (Смарт)',
                        },
                    ]
                    }
                    onChange={onChange}
                >
                </Smart>
            </>}
        </div>
    </>
}

export default OkladEdit
