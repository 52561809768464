import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Paper } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import Layout2 from "./UserModal";
// const StyledPaper = styled(Paper)({
//     maxHeight: 200, // Adjust the height as needed
//     overflow: 'hidden',
// });
function MyAutoComplete(props) {
    props = props.props || props;

    let {localItem, field} = props || {}
    let {isMulti, itemsKey, key, label} = field || {};
    let items = props.items || window.info[itemsKey]
    let value = props.value || localItem[key]


    let query = isMulti ? {multiple: true} : {};
    let valueObj;
    try {
        valueObj = isMulti ? (value || []).reduce((acc, it) => {
            return {...acc, [it]: true}
        }, {}) : {[value]: true}
    } catch (e) {
        valueObj = {}
    }


    label = props?.label || field?.label;
    return <>
        {label && <div className={'labelAutoComplete'}><small>{label}</small></div>}
        <Autocomplete
        {...query}
        disablePortal
        value={items[isMulti ? 'filter' : 'find'](it => valueObj[it._id])}
        options={items || []}
        ListboxProps={{ style: { maxHeight: "300px" }, position: "bottom-start" }}

            // PaperComponent={(props) => <StyledPaper {...props} />}
        getOptionLabel={(option) => [option.surName, option.name, option.workerType == 'agent' ? "(А)" : ''].filter(it => it).join(' ')} // Use 'name' instead of 'label'
        onChange={(e, v) => {
            let value = isMulti ? v.map(it => it?._id) : v?._id;

            try {

            } catch(e) {

            }
            props.onChange && props.onChange(value, key)
        }}
        sx={{width: 10000}}
        renderInput={(params) => <TextField {...params} />}
    /></>
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
    {name: '1', label: 'The Shawshank Redemption', year: 1994, _id: 22},
    {name: '2', label: 'The Sh', year: 1994, _id: 33},

];

export default MyAutoComplete
