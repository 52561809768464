import React, {useState, useEffect} from 'react';
import {
    getStats2,
    gv,
    pubAccount,
    pubAccounts,
    pubContract, pubEmployee,
    pubemployee,
    pubemployees, pubPaidStatus, pubTeam,
    pubType,
    pubTypes
} from "./methods";
import _ from 'underscore';
import Stringify from "../Stringify";
import {ToRub} from "./TopStats";
import {calcTransactions} from "./UserPeriodPreview";
import {StatusPub} from "./TransactionPreview";

let curDate = new Date().toODB()

function TransactionContractPreview(props) {
    let {ind, items, contractId, detailedPreview, onChange} = props;

    let st = {}
    st = getStats2(items, (item) => {
        console.log("qqqqq itemmmmmmmmmm", item.type);
        let type = item.type;
        let keys = [item.team, 'total'];
        return keys
    }, {
        mult: -1, woItems: true,
    })

    let {total, positive, negative} = calcTransactions(items)
    console.log("qqqqq stttttttttttttttt", st);


    let name = pubContract(contractId);
    return <div className={'row transItem'} key={ind} onClick={() => {
        // window.openMainModal({
        //     type: 'transaction', item, onChange
        // })
    }}>

        <div className="col-xs-2">
            <div className="ellipse" title={name}>
                {name}

            </div>
        </div>
        <div className="col-xs-2">
            <ToRub woPull={true}>{total}</ToRub>
        </div>
        {/*{!detailedPreview && <div className="col-xs-9 ">*/}
        {/*    {(items || []).map((item, ind) => {*/}
        {/*       return  (<div key={ind} className={'row hov ' + (isPayProblem ? 'payProblem' : '')} onClick={() => {*/}
        {/*           window.openMainModal({*/}
        {/*               type: 'transaction', item*/}
        {/*           })*/}
        {/*       }}>*/}
        {/*           <ToRub>{item.amountTo}</ToRub>*/}
        {/*       </div>)*/}
        {/*    })}*/}
        {/*</div>}*/}
        <div className="col-xs-8 ">
            {(items || []).map((item, ind) => {
                let {total, positive, negative} = calcTransactions([item])

                let payDate = avanceDate(item, item?.special?.isAvance)
                let isPayProblem = (payDate < curDate) && (item.status !== 'paid')
                let title = `${payDate}`
                // let {total, positive, negative} = calcTransactions(items.filter)
                return (<div key={ind} className={'row hov ' + (isPayProblem ? 'payProblem' : '')} onClick={() => {
                    window.openMainModal({
                        type: 'transaction', item
                    })
                }}>
                    {!detailedPreview && <>
                        <div className="col-xs-2">
                            <StatusPub isPayProblem={isPayProblem}>{item.status}</StatusPub>
                        </div>
                        <div className="col-xs-3">
                            <div>
                                <ToRub woPull={true}>{total}</ToRub>
                            </div>
                        </div>
                        <div className="col-xs-2">
                            <div className="ellipse">
                                {pubTeam(item.teamTo)}
                            </div>
                        </div>
                        <div className="col-xs-5">
                            <div className="ellipse">
                                {(item?.employees || []).map((it, ind) => {
                                    return <div className={'ib mr-5'}>{pubEmployee(it)}</div>
                                })}
                            </div>
                        </div>
                    </>}
                    {detailedPreview && <>
                        <div className="col-xs-2">
                            <div className={'ellipse'} style={{marginBottom: '-7px'}}>
                                <StatusPub isPayProblem={isPayProblem}>{item.status}</StatusPub>

                            </div>
                            <small className={'ellipse label label-' + (isPayProblem ? 'danger' : 'info')}
                                   title={title}>{title}</small>
                            <div>
                                {item?.special?.isAvance ?
                                    <small style={{marginTop: '2px'}}
                                           className={'ellipse label label-' + (isPayProblem ? 'danger' : 'info')}
                                           title={title}>Аванс</small> : ''}
                            </div>
                        </div>
                        <div className="col-xs-2">
                            <div>
                                <ToRub woPull={true}>{total}</ToRub>
                            </div>
                            <div className="ellipse">
                                {pubTeam(item.teamTo)}
                            </div>

                        </div>


                        <div className="col-xs-8">
                            {(item?.items || []).map((it, ind) => {
                                return <div key={ind} className={'row'}>
                                    <div className="col-xs-4">
                                        {it.type === 'prihod_nalog' ?
                                            <div className={'ellipse'}>{pubTeam(it.team)} (корп налог)</div> :
                                            <div className={'ellipse'}>{pubEmployee(it.employee)}</div>}
                                    </div>
                                    <div className="col-xs-4">
                                        <ToRub account={it.account} period={it.period}>{it.amount}</ToRub>
                                    </div>
                                    <div className="col-xs-4">
                                        <div className="ellipse">
                                            {pubAccount(it.account)}
                                        </div>
                                    </div>

                                </div>
                            })}
                        </div>
                    </>}
                    {/*<div className="col-xs-2">*/}
                    {/*    <div className="ellipse">*/}
                    {/*        {pubEmployee(item.employee)}*/}
                    {/*    </div>*/}
                    {/*    <div className={'ellipse'}>*/}
                    {/*        {pubEmployee(item.employeeShadow)}*/}
                    {/*    </div>*/}
                    {/*    <div className={'ellipse'}>*/}
                    {/*        {pubTeam(item?.special?.korp_team)}*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </div>)
            })}
        </div>
    </div>
}


export function avanceDate(item, isAvance) {
    let {contract, period} = item;

    let contractInfo = (window?.info?.contractsObj || {})[contract]
    let {daysToPayMain, daysToPayAvance} = contractInfo || {}

    let days = (isAvance ? daysToPayAvance : daysToPayMain) || 0;
    let periodCd = new Date(period)
    if (!isAvance) {
        periodCd.setMonth(periodCd.getMonth() + 1)
    }
    periodCd.setDate(days)

    return periodCd.toODB()//.toODB()//.toOdb()

}

export default TransactionContractPreview
