import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import MyAutoComplete from "../MyAutocomplete";
import Input from "../../../libs/Input";
import {PremiaEdit} from "../UserPeriodPreview";
import Button from "../../../libs/Button";
import {ToRub} from "../TopStats";
import {AmountEdit} from "./OkladEdit";
import Stringify from "../../Stringify";


function PrihodEdit(props) {

    let {item, onChange} = props;
    item.special ??= {}
    item.special.dbItems ??= {}
    let special = item.special;
    let {dbItems} = item.special;
    dbItems.oklad_nalog ??= {}

    return <>
        <div className="col-xs-4">
            <MyAutoComplete label={'Контракт'} localItem={item} field={{key: 'contract', itemsKey: 'contracts'}}
                            onChange={contract => {
                                onChange({contract})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4">
            <MyAutoComplete label={'Аккаунт'} localItem={item} field={{key: 'accountTo', itemsKey: 'accounts'}}
                            onChange={accountTo => {
                                onChange({accountTo})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4">
            <AmountEdit label={'Сумма'} k={1} value={item.amountTo}
                        accountId={item.accountTo}
                        onChange={amountTo => {
                            onChange({amountTo})
                        }}/>
            {special?.isAgent && <AmountEdit label={'Общая сумма проекта'} k={1} value={special.agentAmount}
                                             accountId={item.accountTo}
                                             onChange={v => {
                                                 special.agentAmount = v
                                                 onChange({special})
                                             }}/>}

            <Button color={4} size={'xs'}

                    onClick={(cb) => {
                        cb && cb()
                        special.isAgent = !special.isAgent;
                        onChange({special})
                    }}>Агент?</Button>


        </div>
        <div className="col-xs-4">
            <MyAutoComplete label={'Сотрудник'} localItem={item} field={{key: 'employee', itemsKey: 'employees'}}
                            onChange={employee => {
                                onChange({employee})
                            }}></MyAutoComplete>
        </div>

        <div className="col-xs-4">
            <MyAutoComplete label={'Команда'} localItem={item} field={{key: 'teamTo', itemsKey: 'allTeams'}}
                            onChange={teamTo => {
                                onChange({teamTo})
                            }}></MyAutoComplete>
        </div>


        <div className="col-xs-12"></div>

        <div className="col-xs-4">
            <MyAutoComplete label={'Сотрудник Шэдоу'} localItem={item}
                            field={{key: 'employeeShadow', itemsKey: 'employees'}}
                            onChange={employeeShadow => {
                                onChange({employeeShadow})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-4" style={{opacity: item.employeeShadow ? 1 : .2}}>
            <MyAutoComplete label={'Команда Шэдоу'} localItem={item} field={{key: 'teamToShadow', itemsKey: 'allTeams'}}
                            onChange={teamToShadow => {
                                onChange({teamToShadow})
                            }}></MyAutoComplete>
        </div>

        <div className="col-xs-4" style={{opacity: item.employeeShadow ? 1 : .2}}>
            <Smart
                defSize={12}
                onChange={(v) => {
                    console.log("qqqqq vvvvvvvvvvvvvvvv!!!!", v);
                    onChange({special: {...v.special}})
                }}
                obj={item}
                items={[
                    {
                        name: '% для шэдоу',
                        key: 'special.shadow_perc'
                    }]}
            ></Smart>
        </div>
        <div className="col-xs-12">


        </div>
        <div className="col-xs-12">
            <Smart
                defSize={4}
                onChange={(v) => {
                    onChange({special: {...v.special}})
                }}
                obj={item}
                items={[
                    {
                        path: 'Main/MyAutocomplete',
                        key: 'special.korp_team',
                        itemsKey: 'teams',
                        label: 'Корп налог тим'
                    },

                    {
                        path: 'Main/MyAutocomplete',
                        key: 'special.korp_account',
                        itemsKey: 'accounts',
                        label: 'Корп налог аккаунт'
                    },
                    {
                        size: 4, childs: [
                            {name: 'КорпН %', key: 'special.korp_perc', size: 4},
                            {
                                name: 'КорпН Фикс', type: 'amount',
                                key: 'special.korp_fix', size: 8,

                            }
                        ]
                    }

                ]}
            ></Smart>
        </div>
        <div className="col-xs-4">
            <div className="ib pull-left " style={{marginTop: '-10px'}}>
                <Input
                    label={'Аванс? (нужно для агентов)'}
                    type={'checkbox'} value={special.isAvance} onChange={v => {
                    special.isAvance = !special.isAvance;
                    onChange({special})
                }}></Input>
            </div>
        </div>

        {/*<div className="col-xs-4">*/}
        {/*    <AmountEdit label={'Налог'} value={dbItems['oklad_nalog'].amountFrom} onChange={v => {*/}
        {/*        dbItems['oklad_nalog'].amountFrom = v;*/}
        {/*        onChange({special})*/}
        {/*    }}/>*/}
        {/*</div>*/}
    </>
}


export default PrihodEdit
