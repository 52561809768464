import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from './Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from 'react-router-dom'

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let k = window.user.get_rub_k();
  return (
    <div className={'headerCont'}>
      <Box sx={{ flexGrow: 1 }}>
        {/*<AppBar position="static">*/}
          <Toolbar>
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  edge="start"*/}
            {/*  color="inherit"*/}
            {/*  aria-label="menu"*/}
            {/*  sx={{ mr: 2 }}*/}
            {/*>*/}
            {/*  <MenuIcon />*/}
            {/*</IconButton>*/}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={'top-links'}>
                {(global.CONFIG.header || []).map((it, ind) => {
                    return (<Link to={it.url} key={ind}>
                        {it.name}
                    </Link>)
                })}
            </Typography>

            <div onClick={handleClick}>{global.user.get_public_name() || 'User'} {k == '1000' ? '₽' : '₽₽₽'}</div>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <Menu></Menu>
              {/*<Typography sx={{ p: 2 }}>*/}
              {/*  The content of the Popover.*/}
              {/*  <ul>*/}
              {/*    <li>113</li>*/}
              {/*    <li>11324</li>*/}
              {/*    <li>11324214</li>*/}
              {/*  </ul>*/}
              {/*</Typography>*/}
            </Popover>
          </Toolbar>
        {/*</AppBar>*/}
      </Box>

    </div>
  );
}
