import React, {useState, useEffect} from 'react';
import Select from 'libs/Select';

global.getSites = (cb) => {
    global.http.get('/sites?per_page=1000').then((r) => {
        console.log("qqqqq sites", r);
        cb && cb(r.items)
    })
}

function SiteSelector(p) {
    const props = p.props;
    const [sites, setSites] = useState([])
    useEffect(() => {
        global.getSites((r) => {
            setSites(r)
        })
    }, [])

    return <div>
        <Select items={sites} value={props.value} onChange={(v) => {
            console.log("qqqqq on Change", v, props);
            props.onChange( v, 'site')
        }
        }></Select>
    </div>
}

export default SiteSelector
