import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import './Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";
import PeriodsSelector, {getDefPeriod} from "./PeriodsSelector";
import ContractModal from "./ContractModal";
import TransaactionPreview from './TransactionPreview'
import TransactionEdit from "./TransactionEdit";
import Button from "../../libs/Button";
import TransactionUserPreview from './TransactionUserPreview';
import TransactionContractPreview from "./TransactionContractPreview";
import UserPeriodPreview, {calcTransactions} from './UserPeriodPreview';
import ContractsPeriodPerview from './ContractsPeriodPerview'
import {pubEmployee, pubTeam, getStats2, pubAccount, pubType, pubContract, setHash, getHash} from "./methods";
import TopStats, {ToRub} from "./TopStats";
import MyAutoComplete from "./MyAutocomplete";
import OfficeUsers from "./OfficeUsers";
import PullPeriods from "./PullPeriods";
import StatsPeriodShort from "./StatsPeriodShort";
import {toRub} from "../methods/GenerateHistory";
import {isGoodRole} from "../../App";
import MyModal from "../../libs/MyModal";
import {getter} from "../methods/Fetcher";
import Stringify from "../Stringify";

let trHttp = getter('/transaction')

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let pub = (it) => {
        return it > 9 ? it : `0${it}`
    }
    let m = global.m;
    let [filter, setFilter] = useState({})
    let [employeesObj, setEmployeesObj] = useState({})
    let [teamsObj, setTeamsObj] = useState({})
    let [employees, setEmployees] = useState([])
    let [contractsObj, setContractsObj] = useState({})
    let [contracts, setContracts] = useState([])
    let [transactions, setTransaactions] = useState([])
    let [trObj, setTrObj] = useState({})
    let [periods, setPeriods] = useState(m.from_to(2, 9).map(it => {
        return `2024-${pub(it)}`
    }))
    let [periodsObj, setPeriodsObj] = useState({})

    useEffect(() => {
        // global.http.get('/contract', {per_page: 10000, filter: {status: 'unactive'}}).then(r => {
        global.http.get('/contract', {per_page: 10000}).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrr", r);
            setContracts(r.items)
        });
        global.http.get('/team', {per_page: 10000}).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrr", r);
            let obj = {}
            _.each(r.items, (item, ind) => {
                obj[item._id] = item;

            })
            setTeamsObj(obj)
        })
        global.http.get('/employee', {per_page: 10000}).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrr", r);
            let obj = {}
            _.each(r.items, (item, ind) => {
                obj[item._id] = item;

            })

            setEmployeesObj(obj)
            setEmployees(r.items)
        })
        global.http.get('/transaction', {per_page: 100000, filter: {type: 'prihod'}}).then(r => {
            console.log("qqqqq transactions", r);
            contractsObj = {}
            trObj = {}
            setTransaactions(r.items)
            _.each(r.items, (item, ind) => {
                if (item.status === 'archived') {
                    return;
                }
                let {period, contract, status, employee, team, _id, name, teamTo, amountTo} = item;
                trObj[contract] ??= {}
                team = teamTo || team;
                trObj[contract][period] ??= [];

                trObj[contract][period].push({status, employee, amount: amountTo})
                periodsObj[period] = true
                contractsObj[contract] = contractsObj[contract] || {employee, name}
                if (team != 1000) {
                    contractsObj[contract].team = team;
                }

            })

            setContractsObj(contractsObj)
            setTrObj(trObj)

        })
    }, [])

    let trCount = true;

    let PERIOD = periods.at(-1)
    let totals2 = {}
    let totals = {}
    let goods = {}
    _.each(contracts, (it, ind) => {
        let emplId = (contractsObj[it._id] || {}).employee || '';
        let teamId = (contractsObj[it._id] || {}).team || '';
        let team = teamsObj[teamId] || {};
        let empl = employeesObj[emplId] || {};
        let counts = 0;
        let goodCounts = 0;
        let contrStatus = 'active'
        let isAgent = true;
        let isCurPeriod = false;
        let _periodsObj = {}
        let isReject = false;
        let potentialCount = 0;

        _.each(periods, (period, ind) => {
            let trIt = (((trObj[it._id] || {})[period] || {}) || [])[0] || {}
            let trIt1 = (((trObj[it._id] || {})[period] || {}) || [])[1] || {}
            let status = trIt.status;
            let amount = trIt.amount;
            let amount1 = trIt1.amount;
            if (amount > 140 || amount1 > 140 || ((amount + amount1) > 160)) {
                isAgent = false;
            }
            if ([1048, 1005, 1006].indexOf(it?._id) > -1) {
                isAgent = false;
            }
            if (!empl?.name) {
                isAgent = false;
            }
            if (amount) {
                potentialCount++
            }
            if ((PERIOD === period) && amount) {
                isCurPeriod = true;
            }
            let isNewNotPaid = PERIOD === period && status !== 'paid'

            if ((status || counts) && !isNewNotPaid) {
                counts++
            }
            if (status === 'reject') {
                isReject = true;
            }
            if (status == 'paid') {
                goodCounts = counts;
            }
            _periodsObj[period] = {amount, amount1, status}
        })

        let isOld = counts > 6 && goodCounts < 2
        if (counts - goodCounts > 0) {
            isReject = true
        }

        if (isAgent && !isOld && !filter[it._id]) {
            let _counts = isReject ? 7 : counts
            let _counts2 = counts
            for (let i = 1; i <= _counts; i++) {
                totals[i] = (totals[i] || 0) + 1;
            }

            for (let i = 1; i <= _counts2; i++) {
                totals2[i] = (totals2[i] || 0) + 1;
            }
            for (let i = 1; i <= goodCounts; i++) {
                goods[i] = (goods[i] || 0) + 1;
            }
        }


        it.calcStats = {
            counts,
            goodCounts,
            isAgent,
            empl,
            team,
            isOld,
            potentialCount,
            periodsObj: _periodsObj,
            isReject: isReject || counts - goodCounts > 3
        }

    })


    let curCount = 0;
    return <div
    contentEditable={true}
        className={'row ' + (filter.detailedPreview ? 'detailedPreview' : 'shortPreview')}>
        <table border={1} className={'table'}>
            <tr>
                <th>
                    Кол-во оплат
                </th>
                <th>
                    Людей в воронке
                </th>
                <th>
                    Людей в воронке (Вылетевший считается дошел до 24-го и не оплатил)
                </th>
                <th>
                    Людей оплатило
                </th>
                <th>
                    % платящих
                </th>
            </tr>
            {(m.from_to(1, 7) || []).map((it, ind) => {
                return (<tr key={ind}>
                    <td>
                        Сделали {it}-ю оплату
                    </td>
                    <td>{totals2[it]}</td>
                    <td>{totals[it]}</td>
                    <td>{goods[it]}</td>
                    <td>{Math.round(100 * goods[it] / (totals[it] || 1))}%</td>
                </tr>)
            })}
        </table>


        <div></div>
        <br/>
        {(Object.keys(filter) || []).map((it, ind) => {
            if (!filter[it]) {
                return null;
            }
            let empl = (employeesObj[(contractsObj[it] || {}).employee]);
            return (<div key={ind} onClick={() => {
                setFilter({...filter, [it]: false})
            }}>
                {it} {empl?.surName} {empl?.name}
            </div>)
        })}


        <table border={1} className={'table'}>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            {(periods || []).map((period, ind) => {
                return <th key={ind}>{period}</th>
            })}
            <th>
                Кол-во ожидаемых оплат
            </th>
            <th>Оплачено</th>
            <th>Статус</th>
            <th>Тип</th>

            {(contracts || []).map((it, ind) => {
                let {calcStats} = it
                let {empl, potentialCount, team, counts, isOld, periodsObj, isReject, goodCounts, isAgent} = calcStats || {}
                if (!isAgent) {
                    return null;
                }
                if (!potentialCount) {
                    return;
                }

                if (isOld) {
                    return;
                }
                if (filter[it._id]) {
                    return null;
                }
                console.log("qqqqq periodsObj", periodsObj);

                // let empl = employeesObj[(contractsObj[it._id] || {}).employee || ''] || {};
                // console.log("qqqqq epm", empl);
                // let counts = 0;
                // let goodCounts = 0;
                // let contrStatus = 'active'
                // let isAgent = true;

                return (<tr key={ind} onClick={() => {
                    setFilter({...filter, [it._id]: true})
                }}>
                    {/*<td>*/}
                    {/*    <Input type={'checkbox'} value={filter[it._id]} onChange={() => {*/}
                    {/*        setFilter({...filter, [it._id]: true})*/}
                    {/*    }}></Input>*/}
                    {/*</td>*/}
                    <td>
                        {++curCount}
                    </td>
                    <td>
                        {it.name}
                    </td>
                    <td>
                        {empl?.surName} {empl?.name}
                    </td>
                    <td>
                        {team?.name === 'Щербина' ? 'Романенко' : team?.name}
                    </td>
                    {(periods || []).map((period, ind) => {
                        let {status, amount, amount1} = (periodsObj || {})[period] || {}
                        return (<td key={ind}>
                            <div
                                style={{background: status === 'reject' ? 'rgb(255, 223, 223)' : status === 'wait' ? 'rgb(255, 255, 224)' : ''}}>{status}</div>
                            <div>
                                {amount ? +amount.toFixed(1) : amount}
                            </div>
                            <div>
                                {amount1 ? +amount1?.toFixed(1) : amount1}
                            </div>
                        </td>)
                    })}
                    <td>
                        {counts}
                    </td>
                    <td>
                        {goodCounts}
                    </td>
                    <td>
                        {isReject ? <div
                            style={{background: 'rgb(255, 223, 223)'}}>ЗАКОНЧИЛИ</div> : counts && (counts === goodCounts) ? 'ГУД' : 'ОЖИДАЕМ'}
                    </td>
                    <td>
                        {isAgent ? 'Агент' : 'OOO'}
                    </td>
                </tr>)
            })}
        </table>


    </div>
}

export default Layout2
