import React, {useState} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart';
import ComponentSearcher from './ComponentSearcher';
import CompVars from './CompVars';
import {pubHours, onChangePrice, calcFront} from './methods/calcMethods';
import Button from 'libs/Button';
import Table from 'libs/Table';
import Input from 'libs/Input';
import {
    Link, Outlet
} from "react-router-dom";
import CompCustomization from "./CompCustomization";
import QuestionComponent from "./QuestionComponent";

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.props = props.props
        this.state = {
            stepDetails: {},
            stepId: 0,
            item: props.props.item
        };
        window.ProjectDetails = this;
        let {onGlobalChange} = props.props || props;
        this.onGlobalChange = onGlobalChange;
        // this.onGlobalChange = props.props || {}).
    }

    onChangeSelection({item, selectedInd, ind, onGlobalChange}) {
        let it0 = item.items[selectedInd];
        let it1 = item.items[selectedInd].childs[ind];
        it1.selected = !it1.selected;
        onGlobalChange(item)
        console.log("qqqqqit0", it0._id, it1._id);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({item: nextProps.props.item})
    }

    proj_statuses = [{
        name: 'К выполнению', value: 'for_to_do'
    }, {
        name: 'Бэклог', value: 'backlog'
    }, {
        name: 'Для теста', value: 'for_test'
    }, {
        name: 'В работе', value: 'in_progress'
    }, {
        name: 'Готово', value: 'done'
    }]

    render() {
        let {proj_statuses} = this;
        let {item = {}, pointId, stepId, stepDetails = {}, selectedInd = 0} = this.state;
        let selectedItem = (item.items || [])[selectedInd]
        let {onGlobalChange} = this.props.props;
        let step = (item.steps || {})[stepId]
        let _this = this;
        // console.log("qqqqq _____", _this.props);

        function calcAll() {
            item = item || {}
            item.hours = 0;
            _.each(item.steps, (step, ind) => {
                console.log("qqqqq steps !!!!!!!!!!!!!!!!!!!!!!!!!",);
                console.log("qqqqq steps", step);
                step = step || {}
                step.hours = 0
                _.each(step.components, (comp, ind) => {
                    let v = calcFront(comp) || {};
                    step.hours += (v.hours || 0)
                    console.log("qqqqq steps comp", comp, v);
                })
                item.hours += step.hours;

            })
        }

        calcAll();

        this.setPointId = (pointId) => {
            console.log("qqqqq set point id", pointId);
            this.setState({pointId})
        }

        item = item || {}

        return <div>
            {/*{pointId} 333222*/}
            <QuestionComponent _id={pointId} onChange={(pointId) => {
                this.setPointId(pointId)
            }}></QuestionComponent>

            <div className="main-score">
            <span className={'pub-hours'}>
                Базовая Оценка: {pubHours(item.hours)}
            </span>
                {/*    <span>*/}
                {/*    Макс. срок: --*/}
                {/*</span>*/}
            </div>
            <Smart
                defSize={3}
                onChange={(item) => {
                    onGlobalChange(item)
                }}
                obj={item}
                items={[
                    {name: 'name', key: 'name', woLabel: true},
                    {name: 'status', type: 'select', key: 'status', items: proj_statuses},
                    {
                        type: 'btn', name: 'Генерация ТЗ', onClick: ({item, onChange}, scb, c) => {
                            // item.items.push({})
                            // onGlobalChange(item)
                            // scb && scb()
                            alert('Этот функционал в процессе добавления')
                        }
                    },

                ]}></Smart>
            <div className="row">
                <div className="col-sm-12">
                    <hr/>
                </div>
                <div className="col-sm-3">
                    <Smart
                        obj={item}
                        items={[{
                            key: 'steps',
                            sortable: true,
                            addName: '+ Добавить этап',
                            notFoundText: 'Этапы не созданы, создайте этап(стейдж) оценки',
                            size: 12,
                            defSize: 12,
                            activeInd: stepId,
                            each: [
                                {
                                    type: 'input', key: 'name', onClick: (a, b, c) => {
                                        console.log('*........ ## click', a, b, c);
                                    },
                                }, {
                                    type: 'text', key: 'hours', defClass: 'small-title',
                                    prefix: 'Total: ',
                                    postfix: 'h'
                                }

                            ],
                            onClick(...args) {
                                console.log("qqqqq click me", args[1]);
                                _this.setState({stepId: args[1]})
                            }

                        }]}

                        onChange={(v) => {
                            onGlobalChange && onGlobalChange(v)
                        }
                        }
                    > </Smart>
                    {/*    ></Smart>*/}
                </div>
                <div className="col-sm-9">
                    {!step && <div>Создайте и выберите этап</div>}
                    {!!step && <div>
                        <ComponentSearcher
                            item={item}
                            onClickItem={(point) => {

                                if (!step) {
                                    return;
                                }
                                step.components = step.components || [];
                                step.stepChildInd = step.stepChildInd || 100

                                step.components.push({_id: point._id, stepChildInd: ++step.stepChildInd});

                                this.setState({item})
                            }}
                        ></ComponentSearcher>

                        <div className={'pub-hours'} style={{marginTop: '25px'}}>{pubHours((step || {}).hours)}</div>

                        <div className={'comps-list-detailed'}>
                            <Smart
                                obj={step}
                                onChange={(it) => {
                                    step = step || {};
                                    step.components = _.map(it.components, v => {
                                        return {_id: v._id, obj: v.obj}
                                    });
                                    this.setState({cd: new Date()})
                                }}
                                _this={this}
                                items={[
                                    {
                                        onClick: (it, ind) => {
                                            this.setState({selectedInd: ind})
                                        },
                                        sortable: true,
                                        key: 'components',
                                        woAdd: true,
                                        size: 12,
                                        notFoundText: 'Выберите компоненты для этапа',
                                        each: [{
                                            size: 12,
                                            Component: LocalSmartComp
                                        }

                                        ]
                                    },
                                ]}
                            ></Smart>
                        </div>
                    </div>

                    }
                </div>


                {/*<div className="col-sm-5">*/}
                {/*    <pre>{JSON.stringify((step || {}), null, 2)}</pre>*/}
                {/*</div>*/}
            </div>
        </div>
    }
}


function LocalSmartComp(props) {
    let {localItem, item} = props;

    let _this = global.ProjectDetails;
    let compItem = global.compObj[localItem._id]
    console.log("qqqqq ddddddd", props, localItem, localItem.obj);
    // let v = calcFront(localItem)
    // console.log("qqqqq vvvvvvvvvvvvvvvvvvv", v);
    let onQuestion = _this.setPointId;

    return <div>

        <CompCustomization
            onQuestion={onQuestion}
            item={{childs: [compItem]}}
            // woDetails={true}
            vars={localItem}
            onChange={(obj, r, vars) => {
                localItem.obj = obj;
                _this.setState({item: _this.state.item})
                _this.onGlobalChange && _this.onGlobalChange(_this.state.item)
                console.log("qqqqq ONQUE", _this, item);

            }
            }
        ></CompCustomization>
    </div>
}

export default ProjectDetails;