import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../../libs/Smart";
import MyAutoComplete from "../MyAutocomplete";
import Input from "../../../libs/Input";
import {PremiaEdit} from "../UserPeriodPreview";
import Button from "../../../libs/Button";
import {ToRub} from "../TopStats";


function OkladEdit(props) {

    let {item, onChange} = props;
    item.special ??= {}
    item.special.dbItems ??= {}
    let special = item.special;
    let {dbItems} = item.special;
    dbItems.oklad_nalog ??= {}

    return <>
        <div className="col-xs-12">
            Распреды
        </div>
        <div className="col-xs-6">
            <MyAutoComplete label={'Команда'} localItem={item} field={{key: 'teamFrom', itemsKey: 'allTeams'}}
                            onChange={teamFrom => {
                                onChange({teamFrom})
                            }}></MyAutoComplete>
        </div>
        <div className="col-xs-12">
            <Smart
                obj={item}
                items={[{
                    key: 'special.raspredItems',
                    defSize: 3,
                    size: 12,
                    addName: 'Добавить участника',
                    each: [{
                        path: 'Main/MyAutocomplete',
                        key: 'account',
                        itemsKey: 'accounts',
                        label: 'Аккаунт',
                    },{
                        path: 'Main/MyAutocomplete',
                        key: 'employee',
                        itemsKey: 'employees',
                        label: 'Кому',
                    }, {
                        name: 'Сумма', key: 'amount', type: 'amount', accountKey: 'account'
                    }, {
                        name: 'Налог', key: 'nalog_k', type: 'nalog'
                    }]
                }]}
                onChange={v => {
                    onChange({special: item.special})
                }}
            >
            </Smart>
        </div>
    </>
}

export default OkladEdit
