import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import './Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [users, setUsers] = useState([])
    let [transactions, setTransactions] = useState([])
    let [contracts, setContracts] = useState([])

    let m = global.m;

    useEffect(() => {
        setUsers(m.from_to(0, 10).map(it => {
            return {name: 'Usr ' + it}
        }))
        setTransactions(m.from_to(0, 20).map(it => {
            return {name: 'TRANS ' + it}
        }))
        setContracts(m.from_to(0, 5).map(it => {
            return {name: 'Contr ' + it}
        }))
    }, [])
    return <div className={'row'}>
        <div className="col-xs-12">
            Period Selector . Pull за пред период
            <hr/>
            <div>Оборот: </div>
            <div>Траты: </div>
            <div>Год траты: </div>
            <div>Прибыль: </div>
            <div>Распреды: </div>
            <div>Дебиторка: </div>
            <hr/>
            Team Selector

        </div>
        <div className="col-xs-12 ">
            {(users || []).map((it, ind) => {
                let size = 1;

                return (<div key={ind} className={'contrItem'}>
                    <div className="col-xs-4">
                        {it.name} <small>{it.total || '185'} / {it.totalNalog || 214}</small>
                    </div>
                    {(['zp', 'bonus', 'godBonus', 'nalogZP', 'nalogBonus', 'nalogGodBonus'] || []).map((it, ind) => {
                        return <div className={"col-xs-" + size} key={ind}>
                            <Input placeholder={it} woLabel={true}></Input>
                        </div>
                    })}
                </div>)
            })}
        </div>
        <div className="col-xs-12 ">
            <hr/>

            {(transactions || []).map((it, ind) => {
                let size = 2;
                return (<div key={ind} className={'contrItem row'}>
                    <div className="col-xs-4">
                        {it.name} {it.category || 'Категория'}
                    </div>
                    {(['amountTo', 'amountFrom', 'accountFrom', 'accountTo'] || []).map((it, ind) => {
                        return <div className={"col-xs-" + size} key={ind}>
                            <Input placeholder={it} woLabel={true}></Input>
                        </div>
                    })}
                </div>)
            })}
        </div>

    </div>
}

export default Layout2
