import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {getFullPeriods} from "./PeriodsSelector";
import Input from "../../libs/Input";
import {getter} from "../methods/Fetcher";

function getYear(name) {
    return (name || '').substring(0, 4)
}

function getMonth(name) {
    return (name || '').substring(5, 7)
}


let ht = getter('/period-stats');
let m = global.m;

function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [gperiods, setGPeriods] = useState(getFullPeriods(2, -1).reduce((acc, it) => {
        let {year, month, name} = it;
        console.log("qqqqq yera", year, month, name);
        if (!+month) {
            return acc;
        }

        acc[year] ??= {}
        acc[year][month] ??= {period: name, year, month}

        return acc;
        // let year = getYear(it.)
    }, {}))


    useEffect(() => {
        ht.get({per_page: 10000}).then(items => {
            _.each(items, (item, ind) => {
                let year = getYear(item.period)
                let month = getMonth(item.period)
                if (!year || !month) {
                    return;
                }
                console.log("qqqqq year month", year, month);

                gperiods[year] ??= {}
                gperiods[year][month] ??= {}

                gperiods[year][month].info = item;
            })

            console.log("qqq year month  qqqqqq year month gperiods 3333", gperiods['2024']['01'].info, items, gperiods);
            setGPeriods({...gperiods})
        })
    }, [])

    console.log("qqqqq gperiods", gperiods);
    return <div>

        Config For stast

        periods list & relative details
        {(Object.keys(gperiods || {})).map((year, ind) => {


            let yearArr = m.from_to(1, 12).map(it => it > 9 ? it : `0${it}`);//Object.keys(gperiods[year] || {})


            let statGroups = [
                {
                    subKey: 'currency',
                    statsFields: [
                        {name: 'USD', key: 'USD'},
                        {name: 'EUR', key: 'EUR'},
                    ]
                },
                {
                    subKey: 'totals',
                    className: 'big',
                    statsFields: [
                        {name: 'Оборот', key: 'oborot'}
                    ]
                }, {
                    subKey: 'korp',
                    className: '',
                    statsFields: [
                        // {name: 'ФиксАкк', key: 'fix_account'},
                        {name: '%', key: 'perc'},
                        {name: 'Фикс', key: 'fix'},
                    ]
                }]
            return (<div key={ind} className={'row'}>
                <strong>
                    <div className="col-xs-1">
                        Год
                    </div>
                    <div className="col-xs-11">
                        <div className="korpItem big">Период</div>
                        {(statGroups || []).map((sg, ind) => {
                            return (<div key={ind} className={'ib'}>
                                {(sg.statsFields || []).map((field, ind) => {
                                    return (<div key={ind} className={'korpItem ' + (field.className || sg.className || '')}>
                                        {field.name}
                                    </div>)
                                })}
                            </div>)
                        })}
                    </div>
                </strong>
                <div className="col-xs-1">
                    {year}
                </div>
                <div className="col-xs-11">
                    {(yearArr || []).map((month, ind) => {
                        let it = gperiods[year][month]
                        it.info = it.info || {}
                        let info = it.info;
                        info.currency = info.currency || {};
                        info.korp = info.korp || {};
                        let period = it.period;


                        return (<div key={ind} className={'row2'}>
                            <div className="korpItem big">{it.period} {info._id &&
                                <span title={info._id}>#</span>}</div>
                            {(statGroups || []).map((sg, ind) => {
                                let subKey = sg.subKey
                                return (<div key={ind} className={'ib'}>
                                    {(sg.statsFields || []).map((field, ind) => {
                                        let key = field.key;
                                        info[subKey] = info[subKey] || {}
                                        return (<div key={ind} className={'korpItem ' + (field.className || sg.className || '')}>
                                            <Input
                                                type={'number'}
                                                placeholder={field.name} woLabel={true} value={info[subKey][key]}
                                                onChange={v => {
                                                    info[subKey][key] = v;
                                                    setGPeriods({...gperiods})
                                                    ht.createUpdate({period}, info, (r) => {
                                                        console.log("qqqqq rrrrrrrrrrrrr", r, it, field);

                                                        it.info = {...r};
                                                        setGPeriods({...gperiods})
                                                    })
                                                }}/>
                                        </div>)
                                    })}


                                </div>)
                            })}
                        </div>)
                    })}
                </div>
            </div>)
        })}


    </div>
}

export default Layout2
