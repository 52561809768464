import React from 'react';
import _ from 'underscore';

export const getUniqueKeys = (items, field) => {
    let obj = {};
    _.each(items, (item, ind) => {
        console.log("qqqqq itttttttttt", item, field, obj);
        obj[item[field] || ''] = true
    })

    return Object.keys(obj)
}

export const getOrCreate = (items, obj1, obj2) => {
    let fields = Object.keys(obj1)
    let arr = items.filter(it => {
        let isOk = true;
        _.each(fields, (key, ind) => {
            if (it[key] != obj1[key]) {
                isOk = false
            }
        })
        return isOk
    })
    arr = arr?.length ? arr : [{...obj1, ...obj2}]
    return arr;
}
export const objMap = (obj, cb) => {
    return Object.keys(obj).map(key => cb && cb(obj[key], key))
}

export const addEmptyValuesIfNeed = (fields, obj, createMapFn) => {
    // let isAnyGood;
    // _.each(fields, (field, ind) => {
    //     let arr = obj[field];
    //     if (arr && arr.length) {
    //         isAnyGood = true;
    //     }
    // })
    // if (!isAnyGood) {
    //     return null;
    // }
    _.each(fields, (field, ind) => {
        let arr = obj[field];
        if (!arr?.length) {
            let v = createMapFn(field)
            obj[field] = [v]
        }
    })

    return obj;

}
const GroupedObjectRenderer = ({data, level = 0, wrapperComponents}) => {
    // Determine the current component to use as the wrapper at this level
    const Wrapper = wrapperComponents[level] || 'div';

    if (Array.isArray(data)) {
        return (
            <Wrapper data={data}>

            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {Object.entries(data).map(([key, value], index) => (
                <div key={key}>
                    <strong>{key}:</strong>
                    <GroupedObjectRenderer data={value} level={level + 1} wrapperComponents={wrapperComponents}/>
                </div>
            ))}
        </Wrapper>
    );
};

export function toArr(obj) {
    return Object.keys(obj).map(key => obj[key])
}
export function createEmptyGroupChild(group, cb) {
    function processNode(node) {
        if (Array.isArray(node)) {
            // If the node is an array, apply the callback to each element.
            node.forEach(child => cb(child));
        } else if (typeof node === 'object' && node !== null) {
            // If the node is an object, recursively process each property.
            Object.values(node).forEach(processNode);
        }
    }

    // Start processing from the root of the group
    processNode(group);
}

export function toGroup(items, fields, filterFn, mapFn, finalMapFn) {
    let arr = items;
    if (filterFn) {
        arr = arr.filter(filterFn)
    }
    const group = {};

    // Helper function to recursively group data
    function placeIntoGroup(base, index, obj) {
        const field = fields[index];
        if (field === undefined) {  // When no more fields are left, push the object
            base.push(mapFn ? mapFn(obj) : obj);
            return;
        }

        const key = typeof field === 'function' ? field(obj) : obj[field];
        console.log("qqqqq key", key, field,);
        base[key] = base[key] || (index === fields.length - 1 ? [] : {});
        placeIntoGroup(base[key], index + 1, obj);
    }

    // Initialize the grouping process
    arr.forEach(obj => {
        placeIntoGroup(group, 0, obj);
    });


    // delete group['']
    // delete group['undefined']

    return group;
}

export function fromGroup({obj, filterFn, mapFn}) {
    const flatArray = [];

    // Helper function to recursively traverse the nested object
    function collectItems(obj) {
        if (Array.isArray(obj)) {
            obj = mapFn ? obj.map(mapFn) : obj;
            flatArray.push(...obj);  // If it's an array, add all items to the flatArray
        } else {
            Object.values(obj).forEach(collectItems);  // Otherwise, recursively go deeper into the object
        }
    }

    collectItems(obj);  // Start the recursive collection
    return filterFn ? flatArray.filter(filterFn) : flatArray;
}


export default GroupedObjectRenderer;